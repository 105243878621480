import { Utils } from '@ukhomeoffice/cop-react-form-renderer';

const uploadDocument = async (
  fileServiceClient,
  attachmentServiceURL,
  submissionData,
  document
) => {
  const { data } = await fileServiceClient.uploadFile(
    null,
    document.file,
    document.name,
    '',
    null,
    `${attachmentServiceURL}/files/restricted/${submissionData.businessKey}`,
    null
  );
  if (data) {
    Utils.Data.setDataItem(document, 'size', data.size);
    Utils.Data.setDataItem(document, 'url', data.url);
    if (Object.prototype.hasOwnProperty.call(document, 'indexInField')) {
      // If the document has 'indexInField' then it came from a Multi File,
      // so we have to put the URL in the correct array entry.
      Utils.Data.setDataItem(
        submissionData,
        `${document.field}.${document.indexInField}.url`,
        data.url,
        true
      );
    } else {
      Utils.Data.setDataItem(submissionData, `${document.field}.url`, data.url, true);
    }
  }
  return document;
};

const uploadDocuments = async (fileServiceClient, attachmentServiceURL, submissionData) => {
  if (fileServiceClient && submissionData) {
    const toUpload = submissionData.meta?.documents?.filter((doc) => !doc.url) || [];
    if (toUpload.length > 0) {
      return Promise.all(
        toUpload.map((document) =>
          uploadDocument(fileServiceClient, attachmentServiceURL, submissionData, document)
        )
      );
    }
  }
  return Promise.resolve();
};

export default uploadDocuments;
