import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useEffect, useContext } from 'react';
import { FormPanelContext } from '../../components/form/utils/FormPanelContext';

const generateConfirmationMessage = (message, email) => {
  const { t } = useTranslation();

  return message ? (
    <>
      {parse(
        Array.isArray(message)
          ? message.join('')
          : message +
              (email
                ? `<p>{t('pages.form.cop-confirmation-panel.confirmation-email-text')}</p>`
                : '')
      )}
    </>
  ) : (
    // default body
    <>
      <p className="govuk-body">{t('pages.form.cop-confirmation-panel.confirmation-message')}</p>
      <p className="govuk-body">
        {t('pages.form.cop-confirmation-panel.what-next-body-p1')}{' '}
        <a className="govuk-body" href="/">
          {t('pages.form.cop-confirmation-panel.return-to-homepage')}
        </a>
        .
      </p>
    </>
  );
};

const FormPageSubmission = ({ businessKey }) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  const { t } = useTranslation();

  const { formPanelContext } = useContext(FormPanelContext);

  if (!formPanelContext) {
    return null;
  }

  const { confirmationEmail, confirmationMessage, confirm } = formPanelContext;

  const title = confirm?.title ?? t('pages.form.cop-confirmation-panel.title');

  const body = generateConfirmationMessage(
    confirm?.message ?? confirmationMessage,
    confirmationEmail
  );

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <div className="govuk-panel govuk-panel--confirmation">
          <h1 className="govuk-panel__title">{title}</h1>
          <div className="govuk-panel__body">
            {t('pages.form.cop-confirmation-panel.ref-no')}
            <br />
            <strong>{businessKey}</strong>
          </div>
        </div>
        {body}
      </div>
    </div>
  );
};

FormPageSubmission.propTypes = {
  businessKey: PropTypes.string.isRequired,
};

export default FormPageSubmission;
