const ROUTE_PATHS = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  ACCESSIBILITY: '/accessibility-statement',
  PRIVACY: '/privacy-and-cookie-policy',
  FORMS: '/forms',
  SUBMIT_FORM: '/submit-a-form',
  REPORTS: '/reports',
  TARGETS: '/targets',
  TARGETS_RORO: '/targets/roro',
  RORO_TARGETS: '/roro/targets',
  TARGETS_AIRPAX: '/targets/airpax',
  AIRPAX_TARGETS: '/airpax/targets',
  TASKS: '/tasks',
  MANDECS: '/mandecs',
  CASES: '/cases',
  TMS: '/tms',
  LOGOUT: '/logout',
  FORMS_ACCOUNT: '/forms/account',
  NEW_ACCOUNT: '/new-account',
  ACCOUNT_UNDER_REVIEW: '/account/under-review',
  EXISTING_ACCOUNT: '/existing-account',
  CONFIRMATION: '/confirmation-page',
  CONFIRMATION_COIR: '/confirmation-page-coir',
  ACCOUNT_CONFIRMATION: '/forms/account/confirmation',
});

export default ROUTE_PATHS;
