// Global imports
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Radios } from '@ukhomeoffice/cop-react-components';

// Local imports
import getOptionsForPortAndMode from '../../utils/Form/getOptionsForPortAndMode';

// Styles.
import './WhatHappenedRadios.scss';

const WhatHappenedRadios = ({
  id,
  fieldId,
  options,
  onChange,
  value,
  formData,
  readonly,
  ...attrs
}) => {
  const validOptions = useMemo(() => {
    const applicableOptions = getOptionsForPortAndMode(formData);
    return options
      .map((option) => {
        // 'Something else' and the 'or' separator should always be shown.
        if (option === 'or' || option.value === 'noItemsFound') {
          return option;
        }
        return applicableOptions.includes(option.value) ? option : null;
      })
      .filter((o) => !!o);
  }, [options, formData]);

  return (
    <Radios
      id={id}
      fieldId={fieldId}
      options={validOptions}
      onChange={onChange}
      value={value}
      readonly={readonly}
      {...attrs}
    />
  );
};

WhatHappenedRadios.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        hint: PropTypes.string,
      }),
      PropTypes.string,
    ])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  formData: PropTypes.shape({}),
  readonly: PropTypes.bool,
};

WhatHappenedRadios.defaultProps = {
  value: null,
  formData: {},
  readonly: false,
};

export default WhatHappenedRadios;
