import { ComponentTypes } from '@ukhomeoffice/cop-react-form-renderer/dist/models';

export const nonUkAddressComponents = [
  {
    id: 'addressLine1',
    fieldId: 'addressLine1',
    label: 'Address line 1',
    type: ComponentTypes.TEXT,
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter address line 1',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 1 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'addressLine2',
    fieldId: 'addressLine2',
    label: 'Address line 2',
    type: ComponentTypes.TEXT,
    required: false,
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 2 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'addressLine3',
    fieldId: 'addressLine3',
    label: 'Address line 3',
    type: ComponentTypes.TEXT,
    required: false,
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 3 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'addressLine4',
    fieldId: 'addressLine4',
    label: 'Address line 4',
    type: ComponentTypes.TEXT,
    required: false,
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 4 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'addressLine5',
    fieldId: 'addressLine5',
    label: 'Address line 5',
    type: ComponentTypes.TEXT,
    required: false,
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 5 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'country',
    fieldId: 'country',
    label: 'Country',
    type: ComponentTypes.AUTOCOMPLETE,
    required: true,
    showAllValues: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Select a country',
      },
    ],
    item: {
      value: 'id',
      label: 'name',
      // eslint-disable-next-line no-template-curly-in-string
      format: '${iso31661alpha3} - ${name}',
    },
    data: {
      url: 'refdata/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true&select=id,name,iso31661alpha3',
    },
    placeholder: 'Start typing to search',
  },
];
