const getPageForFormWithTask = (form, savedPage) => {
  let startPage = form.pages[0]?.id;
  if (savedPage) {
    const currentPage = form.pages.find((page) => {
      return page.id === savedPage;
    });
    if (currentPage?.type === 'pre-task-list') {
      startPage = currentPage.id;
    } else {
      startPage = 'hub';
    }
  }
  return startPage;
};

export default getPageForFormWithTask;
