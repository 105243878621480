// Global imports
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@ukhomeoffice/cop-react-components';
import { useTranslation } from 'react-i18next';
import './AlertPanel.scss';

const AlertPanel = ({
  fieldName,
  fieldValue,
  lmApprovalStatus,
  dlApprovalStatus,
  lmEmail,
  dlEmail,
}) => {
  const { t } = useTranslation();

  // At some stage this should move into ref data
  const getCivilServantStatus = (value) => {
    switch (value) {
      case 'yes':
        return 'a civil servant';
      case 'no-contractor':
        return 'a contractor';
      case 'no-agency':
        return 'agency staff';
      default:
        return '';
    }
  };

  const getAlertHeading = (name, status) => {
    if (name === `${t('pages.form.cop-alert-panel.civil-servant-field')}`) {
      return `${t('pages.form.cop-alert-panel.civil-servant-heading')}`;
    }
    if (name === `${t('pages.form.cop-alert-panel.line-manager-field')}`) {
      if (status === 'expired') {
        return `${t('pages.form.cop-alert-panel.line-manager-expired-heading')}`;
      }
      return `${t('pages.form.cop-alert-panel.line-manager-pending-heading')}`;
    }
    if (name === `${t('pages.form.cop-alert-panel.delegate-field')}`) {
      if (status === 'expired') {
        return `${t('pages.form.cop-alert-panel.delegate-expired-heading')}`;
      }
      return `${t('pages.form.cop-alert-panel.delegate-pending-heading')}`;
    }
    return `${t('pages.form.cop-alert-panel.other-heading', { name })}`;
  };

  const getAlertBody = (name, value, status) => {
    if (name === `${t('pages.form.cop-alert-panel.civil-servant-field')}`) {
      const translatedValue = getCivilServantStatus(value);
      return `${t('pages.form.cop-alert-panel.civil-servant-msg', { translatedValue })}`;
    }
    if (name === `${t('pages.form.cop-alert-panel.line-manager-field')}`) {
      if (status === 'expired') {
        return `${t('pages.form.cop-alert-panel.line-manager-expired-msg', { value })}`;
      }
      return `${t('pages.form.cop-alert-panel.line-manager-pending-msg', { value })}`;
    }
    if (name === `${t('pages.form.cop-alert-panel.delegate-field')}`) {
      if (status === 'expired') {
        return `${t('pages.form.cop-alert-panel.delegate-expired-msg', { value })}`;
      }
      return `${t('pages.form.cop-alert-panel.delegate-pending-msg', { value })}`;
    }
    return `${t('pages.form.cop-alert-panel.other-msg', { name, value })}`;
  };

  const getAlertType = (name, status) => {
    if (
      name === `${t('pages.form.cop-alert-panel.line-manager-field')}` ||
      name === `${t('pages.form.cop-alert-panel.delegate-field')}`
    ) {
      if (status === 'expired') {
        return 'error';
      }
      // If status is not 'expired' it means that a standard alert is needed
      // and for this we need to supply a null value to the classModifiers prop.
      return null;
    }
    // If not an alert for line manager or delegate then always use a success alert.
    return 'success';
  };

  return (
    <>
      {fieldName && fieldValue && !fieldName.includes('email') && (
        <Alert heading={getAlertHeading(fieldName)} classModifiers={getAlertType(fieldName)}>
          {getAlertBody(fieldName, fieldValue)}
        </Alert>
      )}
      {lmApprovalStatus && !['approved', 'rejected'].includes(lmApprovalStatus) && (
        <Alert
          heading={getAlertHeading(
            `${t('pages.form.cop-alert-panel.line-manager-field')}`,
            lmApprovalStatus
          )}
          classModifiers={getAlertType(
            `${t('pages.form.cop-alert-panel.line-manager-field')}`,
            lmApprovalStatus
          )}
        >
          {getAlertBody(
            `${t('pages.form.cop-alert-panel.line-manager-field')}`,
            fieldName === `${t('pages.form.cop-alert-panel.line-manager-field')}`
              ? fieldValue
              : lmEmail,
            lmApprovalStatus
          )}
        </Alert>
      )}
      {dlApprovalStatus && !['approved', 'rejected'].includes(dlApprovalStatus) && (
        <Alert
          heading={getAlertHeading(
            `${t('pages.form.cop-alert-panel.delegate-field')}`,
            dlApprovalStatus
          )}
          classModifiers={getAlertType(
            `${t('pages.form.cop-alert-panel.delegate-field')}`,
            dlApprovalStatus
          )}
        >
          {getAlertBody(
            `${t('pages.form.cop-alert-panel.delegate-field')}`,
            fieldName === `${t('pages.form.cop-alert-panel.delegate-field')}`
              ? fieldValue
              : dlEmail,
            dlApprovalStatus
          )}
        </Alert>
      )}
    </>
  );
};

AlertPanel.defaultProps = {
  fieldName: null,
  fieldValue: null,
  lmApprovalStatus: null,
  dlApprovalStatus: null,
  lmEmail: null,
  dlEmail: null,
};

AlertPanel.propTypes = {
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  lmApprovalStatus: PropTypes.string,
  dlApprovalStatus: PropTypes.string,
  lmEmail: PropTypes.string,
  dlEmail: PropTypes.string,
};

export default AlertPanel;
