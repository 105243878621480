export const targetFields = [];

export const targetItemFields = ['quantity', 'estimatedAmount', 'amountInPoundsSterling'];

const preprocessForEAB2Submit = (formData, patch) => {
  targetFields.forEach((targetField) => {
    if (typeof formData?.[targetField] === 'string') {
      // eslint-disable-next-line no-param-reassign
      formData[targetField] = formData[targetField].replace(/,/g, '');
    }
    if (typeof patch?.[targetField] === 'string') {
      // eslint-disable-next-line no-param-reassign
      patch[targetField] = patch[targetField].replace(/,/g, '');
    }
  });

  formData?.items?.forEach((item) => {
    targetItemFields.forEach((targetField) => {
      if (typeof item[targetField] === 'string') {
        // eslint-disable-next-line no-param-reassign
        item[targetField] = item[targetField].replace(/,/g, '');
      }
    });
  });

  patch?.items?.forEach((item) => {
    targetItemFields.forEach((targetField) => {
      if (typeof item[targetField] === 'string') {
        // eslint-disable-next-line no-param-reassign
        item[targetField] = item[targetField].replace(/,/g, '');
      }
    });
  });

  return { formData, patch };
};

export default preprocessForEAB2Submit;
