export const caseActionFormPrepopulateKey = 'caseActionPrepopulate';

export const formSubmitPath = 'process-definition/key';
export const businessKeyPath = '/businessKey/generate';
export const camundaRoot = '/camunda/engine-rest';

export const mobileWidth = 640;

export const powerBIBranchNames = [
  'Central',
  'Detection Services',
  'Heathrow',
  'Intelligence',
  'National Operations',
  'South',
  'South East and Europe',
];

export const powerBiSchema = 'http://powerbi.com/product/schema#basic';

export const taskSubmitPath = 'task';

export const variableInputFieldKey = 'submitVariableName';

export const formsWithContentPage = ['targetTask'];

export const targetsFormName = 'actionTarget';
export const targetTaskDefinitionKey = 'actionTarget';
export const targetTaskNameLike = 'Action the target:%';

export const tmsTaskNameNotLike = 'TMS%';

export const TARGETER_ACTIONS_MONITOR = 'Monitor only';

export const DATES = {
  REVIEW_DATE_REQUIRED: 'Set date for review of this action is required',
  REVIEW_DATE_INVALID: 'Set date for review of this action is not a valid date',
  REVIEW_DATE_18MONTHS: 'Review date must not be more than 18 months in the future',
  REVIEW_DATE_1MONTH: 'Review date must not be more than 1 month ago',
  REVIEW_DATE_IN_PAST: 'Review date must not be in the past',
  PROD_REV_CLASSNAME: 'govuk-input govuk-date-input__input govuk-input--width-2',
  PROD_REV_CLASSERROR:
    'govuk-input govuk-date-input__input govuk-input--error govuk-input--width-2',
  PROD_YEAR_CLASSNAME: 'govuk-input govuk-date-input__input govuk-input--width-4',
  PROD_YEAR_CLASSERROR:
    'govuk-input govuk-date-input__input govuk-input--error govuk-input--width-4',
};

export const CACHED_TAB = 'cached-tab';

export const PATHS = {
  RORO: '/targets/roro',
  AIRPAX: '/targets/airpax',
  TARGET_PATHS() {
    return [this.AIRPAX, this.RORO];
  },
};

export const COMPONENT_TYPES = {
  CHECKBOXES: 'checkboxes',
  DATE_INPUT: 'date',
  AUTOCOMPLETE: 'autocomplete',
  RADIOS: 'radios',
  SELECT: 'select',
  TEXT_INPUT: 'text',
};

export const DIRECTION = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
};

export const UNKNOWN = 'Unknown';

export const LONG_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const LONG_DAY_DATE_FORMAT = 'D MMMM YYYY [at] HH:mm';
export const UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const LONDON_TIMEZONE = 'Europe/London';

export const DEFAULT_PAST_SUFFIX = 'ago';

export const MOVEMENT_MODES = {
  TOURIST: 'RORO_TOURIST',
  UNACCOMPANIED_FREIGHT: 'RORO_UNACCOMPANIED_FREIGHT',
  ACCOMPANIED_FREIGHT: 'RORO_ACCOMPANIED_FREIGHT',
  AIR_PASSENGER: 'AIR_PASSENGER',
  GENERAL_AVIATION: 'GENERAL_AVIATION',
};

export const RORO_MODES = [
  MOVEMENT_MODES.ACCOMPANIED_FREIGHT,
  MOVEMENT_MODES.UNACCOMPANIED_FREIGHT,
  MOVEMENT_MODES.TOURIST,
];

export const AIRPAX_MODES = [MOVEMENT_MODES.AIR_PASSENGER, MOVEMENT_MODES.GENERAL_AVIATION];

export const GROUP_IDS = {
  RORO: 5,
  AIRPAX: 6,
};

export const GROUP_MEMBER = {
  AIRPAX: 'AIRPAX',
  BOTH: 'BOTH',
  NONE: 'NONE',
  RORO: 'RORO',
};

export const GROUP_DROPDOWN_LABELS = {
  AIRPAX: 'AirPax',
  RORO: 'RoRo',
};

export const GROUP_EXTRACTOR = {
  AIRPAX: 'airPaxFrontLineGroups',
  RCCU: 'rccuGroups',
  RORO: 'roRoFrontLineGroups',
};

export const TAB_STATUS = {
  INCOMING: 'incoming',
  RECEIPTED: 'receipted',
  CAPTURED: 'captured',
  COMPLETE: 'complete',
  ASSIGNED: 'assigned',
  ACCEPTED: 'accepted',
  NEW: 'new',
  WITHDRAWN: 'withdrawn',
};

export const TARGET_STATUS = {
  ACCEPTED: 'ACCEPTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  ASSIGNED: 'ASSIGNED',
  CAPTURED: 'CAPTURED',
  COMPLETE: 'COMPLETE',
  INCOMING: 'INCOMING',
  RCCU_INCOMING: 'RCCU_INCOMING',
  RCCU_RECEIPTED: 'RCCU_RECEIPTED',
  RECEIPTED: 'RECEIPTED',
  NEW: 'NEW',
  WITHDRAWN: 'WITHDRAWN',
};

export const FRONTLINE_OUTCOME = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
  NO_SHOW: 'NO_SHOW',
  MISSED: 'MISSED',
  INSUFFICIENT_RESOURCES: 'INSUFFICIENT_RESOURCES',
  WITHDRAWN: 'WITHDRAWN',
};

export const FRONTLINE_OUTCOME_NAME = {
  [FRONTLINE_OUTCOME.POSITIVE]: 'Positive Exam',
  [FRONTLINE_OUTCOME.NEGATIVE]: 'Negative Exam',
  [FRONTLINE_OUTCOME.NO_SHOW]: 'No Show',
  [FRONTLINE_OUTCOME.MISSED]: 'Missed Target',
  [FRONTLINE_OUTCOME.INSUFFICIENT_RESOURCES]: 'Insufficient Resources',
  [FRONTLINE_OUTCOME.WITHDRAWN]: 'Target Withdrawn',
};

export const ROLE = {
  RCCU: 'RCCU',
  HIGHER_OFFICER: 'HIGHER_OFFICER',
  INTERCEPTING_OFFICER: 'INTERCEPTING_OFFICER',
};

export const ROLE_CODE = {
  NCC: 'bf-ncc-officer',
  RCCU: 'bf-rccu-officer',
  HIGHER_OFFICER: 'bf-higher-officer',
  INTERCEPTING_OFFICER: 'bf-intercepting-officer',
};

export const UTC_DATE_REGEXS = [
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/,
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{9}Z/,
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/,
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}\+\d{4}/,
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z/,
];

export const TMS_TEAM = {
  NCC: 'NCC',
  RCC: 'RCC',
  PORT: 'port',
};

export const TMS_FORM = {
  REASSIGN_TASK: 'cop-reassign-task',
  REASSIGN_TASK_TO_RCC: 'cop-reassign-task-to-rcc',
  CLOSE_TASK: 'cop-close-task',
  CLOSE_TASK_VARIANCE: 'cop-close-task-variance',
  CLOSE_TASK_BREACH: 'cop-close-task-breach',
  NCC_TASK: 'cop-ncc-task-form',
  RCC_TASK: 'cop-rcc-task-form',
  VARIANCE_BREACH_TASK: 'cop-variance-breach-v2',
};

export const TMS_TASK_TYPE = {
  VARIANCE: 'Variance',
  CONTROL_BREACH: 'Control breach',
  BREACH_OP_MANDATE: 'Breach of the operating mandate',
  NCC_TASK: 'NCC task',
  RCC_TASK: 'RCC task',
};

export const ONBOARDING_PERMS = {
  FULL_ACCESS: 'Full access',
  MANDEC: 'Basic access for ManDec',
  REPORTS_ACCESS: 'Basic access + reports',
  COPGE: 'copGE role',
};
