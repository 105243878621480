import { ComponentTypes } from '@ukhomeoffice/cop-react-form-renderer/dist/models';

export const ukAddressComponents = [
  {
    id: 'addressLine1',
    fieldId: 'addressLine1',
    label: 'Address line 1',
    type: ComponentTypes.TEXT,
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter address line 1',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 1 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'addressLine2',
    fieldId: 'addressLine2',
    label: 'Address line 2',
    type: ComponentTypes.TEXT,
    required: false,
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Address line 2 must be 255 characters or less',
      },
    ],
  },
  {
    id: 'townOrCity',
    fieldId: 'townOrCity',
    label: 'Town or city',
    type: ComponentTypes.TEXT,
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter town or city',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Town or city must be 255 characters or less',
      },
    ],
  },
  {
    id: 'county',
    fieldId: 'county',
    label: 'County',
    type: ComponentTypes.TEXT,
    required: false,
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'County must be 255 characters or less',
      },
    ],
  },
  {
    id: 'postcode',
    fieldId: 'postcode',
    label: 'Postcode',
    type: ComponentTypes.TEXT,
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter the postcode',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '9',
        message: 'Postcode must be 8 characters or less',
      },
    ],
    className: ['govuk-input--width-10'],
  },
];
