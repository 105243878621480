/**
 * Function returns true if the seal number exists, false otherwise
 * @param {string} string - the seal number to check
 * @returns false if seal number is unique, error string if not.
 */

const copServiceUrl = `/cop-integration`;

/**
 *
 * @param string
 * @param axiosInstance
 * @returns {Promise<string|boolean>}
 */
async function checkSealNumber(string, axiosInstance, config) {
  const { data } = await axiosInstance.get(`${copServiceUrl}/epms/sealNumber/${string}`);
  if (data) {
    // data.result is false if seal number exists, negate because we want true if error
    return data.result ? false : config.message || 'Seal number is in-use';
  }

  // if no data returned, assume seal number valid
  return false;
}

export default checkSealNumber;
