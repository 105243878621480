export const mapLpiData = (addresses) => {
  if (!addresses || !Array.isArray(addresses)) {
    return [];
  }
  return addresses.map((a) => {
    const incomingAddress = a.LPI;
    if (!incomingAddress) {
      return {};
    }
    const address = {};
    const {
      PAO_TEXT,
      SAO_TEXT,
      PAO_START_NUMBER,
      SAO_START_NUMBER,
      PAO_START_SUFFIX,
      PAO_END_NUMBER,
      STREET_DESCRIPTION,
      TOWN_NAME,
      POSTCODE_LOCATOR,
      ADDRESS,
      ADMINISTRATIVE_AREA,
      ORGANISATION,
      UPRN,
      X_COORDINATE,
      Y_COORDINATE,
      LOCALITY_NAME,
    } = incomingAddress;

    const PAO_END_NUMBER_HYPHEN = PAO_END_NUMBER ? ` - ${PAO_END_NUMBER}` : '';
    const PAO_START_SUFFIX_SPACE = PAO_START_SUFFIX ? ` ${PAO_START_SUFFIX}` : '';

    if (SAO_START_NUMBER) {
      address.addressLine1 = `${SAO_START_NUMBER} ${PAO_TEXT || ''}`;
      if (PAO_START_NUMBER) {
        address.addressLine2 = `${PAO_START_NUMBER}${PAO_START_SUFFIX_SPACE}${
          !PAO_START_SUFFIX && PAO_END_NUMBER ? PAO_END_NUMBER_HYPHEN : ''
        } ${STREET_DESCRIPTION}`;
      } else {
        address.addressLine2 = STREET_DESCRIPTION;
      }
    } else if (SAO_TEXT && PAO_TEXT) {
      address.addressLine1 = `${SAO_TEXT} ${PAO_TEXT}`;
      if (PAO_START_NUMBER) {
        address.addressLine2 = `${PAO_START_NUMBER}${PAO_START_SUFFIX_SPACE}${PAO_END_NUMBER_HYPHEN} ${STREET_DESCRIPTION}`;
      } else {
        address.addressLine2 = STREET_DESCRIPTION;
      }
    } else if (SAO_TEXT && PAO_START_NUMBER) {
      address.addressLine1 = SAO_TEXT;
      address.addressLine2 = `${PAO_START_NUMBER} ${STREET_DESCRIPTION}`;
    } else if (PAO_START_NUMBER) {
      if (PAO_TEXT) {
        address.addressLine1 = PAO_TEXT;
        address.addressLine2 = `${PAO_START_NUMBER}${PAO_END_NUMBER_HYPHEN} ${STREET_DESCRIPTION}`;
      } else {
        address.addressLine1 = `${PAO_START_NUMBER}${PAO_END_NUMBER_HYPHEN} ${STREET_DESCRIPTION}`;
        address.addressLine2 = LOCALITY_NAME;
      }
    } else {
      address.addressLine1 = PAO_TEXT;
      address.addressLine2 = STREET_DESCRIPTION;
    }

    if (ORGANISATION && !address.addressLine1.includes(ORGANISATION)) {
      address.addressLine1 = `${ORGANISATION} ${address.addressLine1}`;
    }

    if (ADMINISTRATIVE_AREA) {
      address.county = ADMINISTRATIVE_AREA;
    }

    address.townOrCity = TOWN_NAME;
    address.postcode = POSTCODE_LOCATOR;
    address.value = ADDRESS;
    address.label = ADDRESS;
    address.uprn = UPRN;
    address.xCoordinate = X_COORDINATE;
    address.yCoordinate = Y_COORDINATE;

    return address;
  });
};

export const sortAddresses = (addresses) =>
  addresses.sort((a, b) => {
    const addressOne = a.LPI;
    const addressTwo = b.LPI;

    // Maintain MATCH order
    if (addressTwo.MATCH < addressOne.MATCH) {
      return -1;
    }
    if (addressTwo.MATCH > addressOne.MATCH) {
      return 1;
    }

    // Sort on address number and put any addresses with numbers after those without
    if (addressOne.PAO_START_NUMBER && addressTwo.PAO_START_NUMBER) {
      if (parseInt(addressOne.PAO_START_NUMBER, 10) < parseInt(addressTwo.PAO_START_NUMBER, 10)) {
        return -1;
      }
      if (parseInt(addressOne.PAO_START_NUMBER, 10) > parseInt(addressTwo.PAO_START_NUMBER, 10)) {
        return 1;
      }
    } else if (addressOne.PAO_START_NUMBER && !addressTwo.PAO_START_NUMBER) {
      return 1;
    }

    // Finally sort on street description
    return addressOne.STREET_DESCRIPTION.localeCompare(addressTwo.STREET_DESCRIPTION);
  });

export const customTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary50: '#1d70b8',
    primary25: '#1d70b8',
    primary: '#1d70b8',
    dangerLight: '#d4351c',
  },
});
