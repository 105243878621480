// Global imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Select } from '@ukhomeoffice/cop-react-components';

// Local imports
import { useAxios } from '../../utils/hooks';

const StorageLocationSelect = ({ id, fieldId, onChange, port, lockup, value, ...attrs }) => {
  const [locations, setLocations] = useState([]);
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const api = '/camunda/data-service/station-storage-location';
        let response = await axiosInstance.get(port ? `${api}/${port}` : api);
        // get whole list if no data
        if (!response?.data.length) {
          response = await axiosInstance.get(api);
        }
        const options = [];
        const items = response?.data;
        // if a lockup provided, filter items by that lockup
        const lockups = lockup ? items.filter((l) => l.station.value === lockup) : items;
        lockups.forEach((l) => {
          const storageLocations = l.storageLocations.map((location) => {
            return {
              value: location.value,
              label: location.description,
            };
          });
          options.push(...storageLocations);
        });
        setLocations(options);
      } catch {
        setLocations([]);
      }
    };
    if (axiosInstance) {
      fetchLocations();
    }
  }, [axiosInstance, port, lockup]);

  return (
    <Select
      id={id}
      fieldId={fieldId}
      options={locations}
      onChange={onChange}
      type="select"
      value={value}
      returnFullData
      {...attrs}
    />
  );
};

StorageLocationSelect.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  port: PropTypes.string,
  lockup: PropTypes.string,
  value: PropTypes.string,
};

StorageLocationSelect.defaultProps = {
  port: null,
  lockup: null,
  value: null,
};

export default StorageLocationSelect;
