import { transformPayload as transformPayloadForEpms } from './epmsPayloadConfig';

/**
 * Sets up the submission payload, the correct axios method to use, and the appropriate URL.
 * @param {string} type The type of submission.
 * @param {string} formName The name of the form to be submitted.
 * @param {object} payload The raw payload for submission.
 * @returns Everything needed for submission to Camunda.
 */
const adjustPayloadForType = (type, formName, payload) => {
  const { id, businessKey, form } = payload;
  let submissionPayload = { ...payload };
  let status = { type: 'string', value: 'DRAFT' };
  if (type === 'submit') {
    // Final submission so clear out the draftForm property.
    delete submissionPayload.form.draftForm;
    status = undefined;
  }
  if (type === 'convertForEpms') {
    submissionPayload = transformPayloadForEpms(submissionPayload);
  }
  const jsonPart = {
    type: 'json',
    value: JSON.stringify(submissionPayload),
  };
  if (['submit', 'convertForEpms'].includes(type) || !id) {
    const initiatedBy = { type: 'string', value: form.submittedBy };
    return {
      variables: {
        [formName]: jsonPart,
        initiatedBy,
        status,
      },
      businessKey,
    };
  }
  return jsonPart;
};

export default adjustPayloadForType;
