import React from 'react';

const PendingAccountPage = () => {
  return (
    <>
      <h1>Your account is under review</h1>
      <p>
        We are currently reviewing your details and determining your level of access to operational
        forms.
      </p>
      <p>We will send you a confirmation email after we have checked your details.</p>
    </>
  );
};

export default PendingAccountPage;
