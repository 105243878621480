const londonTimeZone = 'Europe/London';

const isUKLocale = () => {
  return londonTimeZone === Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const dateFromString = (dateString) => {
  const parts = dateString.trim().split('-');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  return new Date(year, month - 1, day);
};

const longDateString = (date) => {
  return `${date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })}`;
};

const fetchForm = async (axiosInstance, pullForm, versionId) => {
  if (axiosInstance) {
    const formEndpoint = 'copform';
    try {
      const { data } = await axiosInstance.get(`/${formEndpoint}/${pullForm}/version/${versionId}`);
      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(
        `Error fetching form json for '${pullForm}'/'${versionId}' with eception: ${err.message}`
      );
    }
  }
  return {};
};

const extractFormComponents = (formJson) => {
  return formJson.components.reduce((map, obj) => {
    const newMap = map;
    newMap[obj.id] = obj;
    if (obj.data?.options) {
      obj.data?.options.forEach((item) => {
        if (item.nested) {
          item.nested.forEach((nestedComponent) => {
            const nLabel = nestedComponent.label;
            newMap[nestedComponent.id] = {
              ...nestedComponent,
              label: `${obj.label ? obj.label : obj.cya_label} ${nLabel}`,
            };
          });
        }
      });
    }
    if (obj.type === 'collection' && obj.item) {
      obj.item.forEach((item) => {
        const nLabel = item.label;
        newMap[item.id] = { ...item, label: `${obj.label ? obj.label : obj.cya_label} ${nLabel}` };
      });
    }
    if (obj.type === 'container' && obj.components) {
      obj.components.forEach((item) => {
        const nLabel = item.label;
        newMap[item.id] = { ...item, label: `${obj.label ? obj.label : obj.cya_label} ${nLabel}` };
      });
    }
    return newMap;
  }, {});
};

const getTeamName = async (axiosInstance, refDataPath, submissionTeamId) => {
  try {
    const fResponse = await axiosInstance.get(
      `${refDataPath}/team?filter=id=eq.${submissionTeamId}`
    );
    if (fResponse.status === 200) {
      return fResponse.data.data[0].name;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error while fetching team by id '${submissionTeamId}': ${err}`);
  }
  return undefined;
};

const getCollectionPages = (formJson) => {
  return formJson.pages.reduce((map, page) => {
    const newMap = map;
    if (page.collection) {
      newMap[page.collection.name] = page;
    }
    return newMap;
  }, {});
};

const updatePayloadWithUI = async (axiosInstance, payload) => {
  const { historyValue, entryValue } = payload;
  if (historyValue && historyValue.form.formVersionId !== entryValue.form.formVersionId) {
    const previousFormJson = await fetchForm(
      axiosInstance,
      historyValue.form.name,
      historyValue.form.formVersionId
    );
    const currentFormJson = await fetchForm(
      axiosInstance,
      entryValue.form.name,
      entryValue.form.formVersionId
    );
    const previousFormComponents = extractFormComponents(previousFormJson);
    const currentFormComponents = extractFormComponents(currentFormJson);
    const previousCollectionPages = getCollectionPages(previousFormJson);
    const currentCollectionPages = getCollectionPages(currentFormJson);
    return {
      ...payload,
      formComponents: { ...previousFormComponents, ...currentFormComponents },
      collectionPages: { ...previousCollectionPages, ...currentCollectionPages },
    };
  }

  const currentFormJson = await fetchForm(
    axiosInstance,
    entryValue.form.name,
    entryValue.form.formVersionId
  );
  return {
    ...payload,
    formComponents: extractFormComponents(currentFormJson),
    collectionPages: getCollectionPages(currentFormJson),
  };
};

export default {
  isUKLocale,
  dateFromString,
  longDateString,
  fetchForm,
  extractFormComponents,
  getTeamName,
  updatePayloadWithUI,
};
