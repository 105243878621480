import Transform from './payloadTransformations';

/**
 * Applies the specified transformations to an object.
 * @param {object} entry The entry object to map.
 * @param {object} config The object containing all transformations to be applied.
 * @returns entry with the transformations in config applied.
 */
/* eslint-disable no-param-reassign */
const applyTransformations = (entry, config) => {
  let entryCopy = { ...entry };
  Object.entries(config).forEach(([key, actionList]) => {
    Object.values(actionList).forEach((action) => {
      const transform = action?.action;
      switch (transform) {
        case Transform.Remove:
          delete entryCopy[key];
          break;
        case Transform.Rename:
          if (key in entryCopy) {
            entryCopy[action.to] = action.from ? entryCopy[key][action.from] : entryCopy[key];
            delete entryCopy[key];
          }
          break;
        case Transform.Spread:
          entryCopy = { ...entryCopy, ...entryCopy[key] };
          break;
        case Transform.Map:
          // only want specific data
          if (entryCopy[key]) {
            const ref = {};
            Object.entries(action.fields).forEach(([newField, oldField]) => {
              const [main, sub] = oldField.split('.');
              const value = sub ? entryCopy[main]?.[sub] : entryCopy[key][main];
              ref[newField] = (value ?? '').toString();
            });
            entryCopy[key] = ref;
          }
          break;
        case Transform.Parse:
          if (typeof action.function === 'function') {
            entryCopy[key] = action.function(entryCopy[key]);
          }
          break;
        case Transform.Default:
          entryCopy[key] = entryCopy[key] ?? action.default;
          // Nullish coalescing operator returns left side for empty string, ePMS fails if given
          // an empty string for a required field so we need to catch that here
          if (entryCopy[key] === '') {
            entryCopy[key] = action.default;
          }
          break;
        // Take data collected in a single field and a collection and consolidate into single array
        case Transform.Consolidate: {
          const combinedArray = entryCopy[action.collection] || [];
          if (entryCopy[key]) {
            combinedArray.push(entryCopy[key]);
          }
          if (combinedArray.length) {
            entryCopy[action.rename || action.collection] = action.subField
              ? combinedArray.map((subEntry) => {
                  const subField = subEntry[action.subField] || subEntry[key]?.[action.subField];
                  return { [action.singleField]: subField };
                })
              : combinedArray;
          }
          break;
        }
        default:
          break;
      }
    });
  });
  return entryCopy;
};

export default applyTransformations;
