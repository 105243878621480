// Global imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Select } from '@ukhomeoffice/cop-react-components';

// Local imports
import { useAxios } from '../../utils/hooks';

const UnitOfMeasureSelect = ({ id, fieldId, onChange, value, item, acceptedUnits, ...attrs }) => {
  const [units, setUnits] = useState([]);
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const api = '/refdata/v2/entities/unit';
        const response = await axiosInstance.get(api);
        const validUnits =
          acceptedUnits.length === 0
            ? response.data.data
            : response.data.data?.filter((unit) => acceptedUnits.includes(unit.code));
        setUnits(
          validUnits.map((unit) => {
            return {
              ...unit,
              value: unit[item?.value].toString(),
              label: unit[item?.label],
            };
          })
        );
      } catch {
        setUnits([]);
      }
    };
    if (axiosInstance) {
      fetchUnits();
    }
  }, [axiosInstance, acceptedUnits]);

  return (
    <Select
      id={id}
      fieldId={fieldId}
      options={units}
      onChange={onChange}
      type="select"
      value={value}
      item={item}
      returnFullData
      {...attrs}
    />
  );
};

UnitOfMeasureSelect.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    format: PropTypes.string,
  }),
  acceptedUnits: PropTypes.arrayOf(PropTypes.string),
};

UnitOfMeasureSelect.defaultProps = {
  value: null,
  item: { value: 'value', label: 'label' },
  acceptedUnits: [],
};

export default UnitOfMeasureSelect;
