const fieldsToKeep = [
  // Seal details fields.
  'itemCategory',
  'itemSubCategory',
  'sealNumber',
  'exhibitReference',
  'seizedOrDetained',
  'legalReasonForSeizing',
  'legalReasonForDetaining',

  // Storage details fields.
  'lockup',
  'willTheItemBeStored',
  'storageLocation',
  'whatIsTheInitialMovement?',
  'shouldThisItemBeDestroyed',
  'instructionsToThePropertyOfficer',
  'giveAReason',
  'specialHandlingInstructions',
  'type',
  'specialStorageConditions',

  // Referral details fields.
  'hasAnotherAgencyAdoptedTheItem',
  'agency',
  'epmsOfficerEmails',
  'agencyReferenceCode',
  'didTheyAdopt',

  // Search details fields.
  'detectionTechnologies',
  'searchTechniques',
  'concealmentMethods',
  'constructedOrAdapted',
  'constructedDescription',

  // Photo evidence fields.
  'doYouHavePhotosOfTheItemToAdd',
  'photos',
  'whatDoThePhotosShow',

  // Misc item fields.
  'id',
];

const deleteUnwantedFields = (item) => {
  // eslint-disable-next-line no-param-reassign
  Object.keys(item).forEach((key) => fieldsToKeep.includes(key) || delete item[key]);
  return item;
};

const checkIfCategoryChanged = (dataItem, patchItem) => {
  if (!dataItem || !patchItem) {
    return false;
  }
  return (
    dataItem.itemCategory.id !== patchItem.itemCategory.id ||
    dataItem.itemSubCategory.id !== patchItem.itemSubCategory.id
  );
};

const getIndexOfActiveItem = (data, itemsActiveId) => {
  return data.items?.findIndex((item) => item.id === itemsActiveId);
};

/**
 * Checks if the active items category or subcategory has changed, if so clears
 * all properties apart from those in fieldsToKeep
 * @param {*} formData full form data - has to be updated
 * @param {*} patch  contains only the current change to the form data
 * @param {*} data form data without the current change, can be used to tell what changed
 * @returns
 */
const clearIfCategoryChanged = (formData, patch, data) => {
  const formDataCopy = JSON.parse(JSON.stringify(formData));
  const patchCopy = JSON.parse(JSON.stringify(patch));
  const dataIndex = getIndexOfActiveItem(data, data.itemsActiveId);
  const patchIndex = getIndexOfActiveItem(patch, data.itemsActiveId);
  if (dataIndex > -1 && patchIndex > -1) {
    const dataItem = data.items[dataIndex];
    const patchItem = patch.items?.[patchIndex];
    if (checkIfCategoryChanged(dataItem, patchItem)) {
      patchCopy.items[patchIndex] = deleteUnwantedFields(patchCopy.items[patchIndex]);
      formDataCopy.items[dataIndex] = deleteUnwantedFields(formDataCopy.items[dataIndex]);
    }
  }
  // Restore any photo data lost in the stringify/parse above.
  formData.items?.forEach((item, index) => {
    if (item.photos) {
      formDataCopy.items[index].photos = item.photos;
    }
  });
  patch.items?.forEach((item, index) => {
    if (item.photos) {
      patchCopy.items[index].photos = item.photos;
    }
  });

  return { formData: formDataCopy, patch: patchCopy };
};

export default clearIfCategoryChanged;
