import { ComponentTypes } from '@ukhomeoffice/cop-react-form-renderer/dist/models';

export const nationalIdentityCardComponents = [
  {
    id: 'givenNames',
    fieldId: 'givenNames',
    type: ComponentTypes.TEXT,
    label: 'Given names',
    hint: 'Their first and middle names',
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter a given name',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Given names must be 255 characters or less',
      },
    ],
  },
  {
    id: 'surname',
    fieldId: 'surname',
    label: 'Surname',
    hint: 'If they have more than one surname, enter them all',
    type: ComponentTypes.TEXT,
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter a surname',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Surname must be 255 characters or less',
      },
    ],
  },
  {
    id: 'dateOfBirth',
    fieldId: 'dateOfBirth',
    label: 'Date of birth',
    type: ComponentTypes.DATE,
    hint: 'For example, 27 3 1983',
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter a date of birth',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeInThePast',
        message: 'Date of birth must be in the past',
      },
      {
        function: 'mustBeAfter',
        unit: 'year',
        value: -120,
        message: 'Date of birth must be less than 120 years ago',
      },
    ],
  },
  {
    id: 'sex',
    fieldId: 'sex',
    label: 'Sex',
    type: 'radios',
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Select a sex',
      },
    ],
    data: {
      options: [
        {
          value: 'female',
          label: 'Female',
        },
        {
          value: 'male',
          label: 'Male',
        },
        {
          value: 'other',
          label: 'X or other',
        },
      ],
    },
  },
  {
    id: 'number',
    fieldId: 'number',
    type: ComponentTypes.TEXT,
    label: 'Card number',
    hint: 'This can contain letters and numbers. For example, 120382978A',
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Enter a card number',
      },
    ],
    additionalValidation: [
      {
        function: 'mustBeShorterThan',
        value: '256',
        message: 'Card number must be 255 characters or less',
      },
    ],
  },
  {
    id: 'countryOfIssue',
    fieldId: 'countryOfIssue',
    type: ComponentTypes.AUTOCOMPLETE,
    label: 'Country of issue',
    hint: "This could also be shown as 'place of issue' or 'issuing authority'",
    placeholder: 'Start typing to search',
    showAllValues: true,
    required: true,
    custom_errors: [
      {
        type: 'required',
        message: 'Select a country',
      },
    ],
    item: {
      value: 'id',
      label: 'name',
      // eslint-disable-next-line no-template-curly-in-string
      format: '${iso31661alpha3} - ${name}',
    },
    data: {
      url: 'refdata/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true&select=id,name,iso31661alpha3',
    },
  },
  {
    id: 'nationality',
    fieldId: 'nationality',
    type: ComponentTypes.AUTOCOMPLETE,
    label: 'Nationality',
    placeholder: 'Start typing to search',
    showAllValues: true,
    required: true,
    item: {
      value: 'id',
      label: 'name',
      // eslint-disable-next-line no-template-curly-in-string
      format: '${iso31661alpha3} - ${name}',
    },
    data: {
      url: 'refdata/v2/entities/country?sort=name.asc&mode=dataOnly&validDateTime=true&select=id,name,iso31661alpha3',
    },
    custom_errors: [
      {
        type: 'required',
        message: 'Select a nationality',
      },
    ],
  },
  {
    id: 'expiryDate',
    fieldId: 'expiryDate',
    type: ComponentTypes.DATE,
    label: 'Expiry date',
    hint: 'For example, 27 3 2027',
    required: false,
    additionalValidation: [
      {
        function: 'mustBeInTheFuture',
        message: 'Expiry date must be in the future',
      },
    ],
  },
];
