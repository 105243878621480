import getProcessInstance from './getProcessInstance';

const CONTEXT_PROPERTIES = [
  'environmentContext',
  'staffDetailsDataContext',
  'extendedStaffDetailsContext',
  'shiftDetailsContext',
  'processContext',
  'taskContext',
  'keycloakContext',
];

/**
 * Gets information about the form for the submission payload.
 * @param {object} formInfo Information about the form.
 * @param {string} submittedByEmail The email address of who is submitting the form.
 * @param {string} submittedByName The given anme of the user who is submitting the form.
 * @returns An object containing information about the form.
 */
export const getFormPayload = (
  formInfo,
  submittedByEmail,
  submittedByName,
  submissionTeam,
  formInstanceId
) => {
  const { id, version, schemaVersion, title, name } = formInfo;
  if (submissionTeam == null) {
    return {
      formId: id,
      formVersionId: version,
      formSchemaVersion: schemaVersion,
      title,
      name,
      submissionDate: new Date(),
      submittedBy: submittedByEmail,
      submittingUsersName: submittedByName,
      draftForm: true,
      formInstanceId,
    };
  }
  return {
    formId: id,
    formVersionId: version,
    formSchemaVersion: schemaVersion,
    title,
    name,
    submissionDate: new Date(),
    submittedBy: submittedByEmail,
    submittingUsersName: submittedByName,
    submissionTeam,
    draftForm: true,
    formInstanceId,
  };
};

/**
 * Accepts a raw submission payload and cleans it up.
 * @param {object} formInfo Information about the form.
 * @param {object} payload The raw payload for submission.
 * @param {string} submittedByEmail The email address of who is submitting the form.
 * @param {string} submittedByName The given name of the user who is submitting the form.
 * @returns A clean submission payload.
 */
const cleanSubmission = (formInfo, payload, submittedByEmail, submittedByName, submissionTeam) => {
  const { businessKey, id } = getProcessInstance(payload.processContext);
  const submissionPayload = {
    id,
    businessKey,
    ...payload,
    form: getFormPayload(
      formInfo,
      submittedByEmail,
      submittedByName,
      submissionTeam,
      payload.formInstanceId
    ),
  };
  // None of the contexts should be in the payload.
  CONTEXT_PROPERTIES.forEach((context) => {
    delete submissionPayload[context];
  });
  return submissionPayload;
};

export default cleanSubmission;
