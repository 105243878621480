const ensureItemPhotosExist = (formData, patch) => {
  const newMetaDocs = [];
  formData.items?.forEach((item, itemIndex) => {
    item.photos?.forEach((photo, photoIndex) => {
      newMetaDocs.push({
        ...photo,
        field: `items[${itemIndex}].photos`,
        indexInField: photoIndex,
      });
    });
  });
  return {
    formData: {
      ...formData,
      meta: {
        documents: newMetaDocs,
      },
    },
    patch,
  };
};

export default ensureItemPhotosExist;
