import FormRenderer, { Utils } from '@ukhomeoffice/cop-react-form-renderer';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAxios } from '../../../utils/hooks';
import { getRenderer, Renderers } from '../../../utils/Form';

const AlternativeFormCYA = ({ config }) => {
  const axiosInstance = useAxios();

  const [form, setForm] = useState({
    formSelected: '',
    isLoading: false,
    data: null,
  });

  const attrs = Utils.Component.clean(config);

  useEffect(() => {
    const fetchForm = async (pullForm, versionId) => {
      if (axiosInstance) {
        try {
          setForm({
            formSelected: versionId,
            isLoading: true,
          });
          const copFormEndpoint = versionId
            ? `/copform/${pullForm}/version/${versionId}`
            : `/copform/latest/${pullForm}`;
          const formIOEndpoint = `/form/name/${pullForm}`;
          const formEndpoint =
            getRenderer(pullForm) === Renderers.REACT ? copFormEndpoint : formIOEndpoint;
          const { data } = await axiosInstance.get(formEndpoint);
          if (formEndpoint.includes('copform')) {
            if (data.type === 'task-list') {
              data.type = 'task-list-cya';
              data.cya = {
                hide_page_titles: false,
                hide_actions: true,
              };
            } else {
              data.type = 'hub-and-spoke';
              data.cya = {
                hide_page_titles: true,
                hide_actions: true,
              };
              data.hub = { format: 'CYA' };
            }
          }

          setForm({
            isLoading: false,
            data,
          });
        } catch (e) {
          setForm({
            formSelected: '',
            isLoading: false,
            data: null,
          });
        }
      }
    };

    fetchForm(attrs?.formKey, attrs?.formVersion);
  }, [axiosInstance, attrs?.formKey, attrs?.formVersion]);

  return form.isLoading ? (
    <p>Loading</p>
  ) : (
    <div className="cya" id={config?.id}>
      {form && form.data && (
        <FormRenderer
          {...form.data}
          data={{
            ...config?.formData,
            formStatus: {
              page: 'cya',
            },
          }}
          cya={{}}
          hide_title
          noChangeAction
          noGroupAction
          hide_actions
        />
      )}
    </div>
  );
};

AlternativeFormCYA.defaultProps = {
  config: {
    formData: {
      form: {
        formId: '',
        formVersionId: '',
      },
    },
  },
};

AlternativeFormCYA.propTypes = {
  config: PropTypes.shape({
    formData: PropTypes.shape(),
    form: PropTypes.shape({
      formId: PropTypes.string,
      formVersionId: PropTypes.string,
    }),
    id: PropTypes.string,
  }),
};

export default AlternativeFormCYA;
