import { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useCurrentRoute } from 'react-navi';
import config from 'react-global-configuration';

import { useKeycloak } from './KeycloakContext';
import Logger from './logger';
import { AlertContext } from './AlertContext';
import { TeamContext } from './TeamContext';
import { CurrentGroupContext } from './CurrentGroupContext';
import { GroupsContext } from './GroupsContext';
import { LocationContext } from './LocationContext';
import { UserContext } from './UserContext';
import { GROUP_MEMBER, ROLE } from './constants';
import authClient from './AuthClient';

export async function loadGroup(teamid, axiosInstance, source, setCurrentGroup, setGroupLoaded) {
  const refDataPath = config.get('refDataPath');
  try {
    if (teamid) {
      const response = await axiosInstance.get(`${refDataPath}/groups?filter=teamid=eq.${teamid}`, {
        params: {
          select:
            'id,displayname,code,grouptypeid,branchid,keycloakgrouppath,locationid,name,selfmanaged,teamid',
        },
        cancelToken: source.token,
      });
      setCurrentGroup(response.data.data[0]);
    }
  } catch (error) {
    setCurrentGroup(undefined);
  } finally {
    setGroupLoaded(true);
  }
}

export async function loadTeam(teamId, axiosInstance, source, setTeam) {
  const refDataPath = config.get('refDataPath');

  try {
    if (teamId) {
      const response = await axiosInstance.get(`${refDataPath}/team?filter=id=eq.${teamId}`, {
        cancelToken: source.token,
      });
      const fetchedTeam = response.data.data[0];
      // Coerces values of team object from numbers to strings to use in shift
      const reformattedTeam = _.mapValues(fetchedTeam, (elem) => {
        return typeof elem === 'number' ? String(elem) : elem;
      });
      setTeam(reformattedTeam);
    } else {
      // TODO: Redirect the user here because they have no teamId in KC...
    }
  } catch (error) {
    setTeam({});
  }
}

export const useAxios = () => {
  const { keycloak, initialized } = useKeycloak();
  const [axiosInstance, setAxiosInstance] = useState({});
  const { setAlertContext } = useContext(AlertContext);

  const routeRef = useRef(useCurrentRoute());
  const setAlertRef = useRef(setAlertContext);
  const client = authClient();

  useEffect(() => {
    if (initialized) {
      const instance = axios.create({
        baseURL: '/',
      });
      instance.interceptors.request.use(
        async (configuration) => {
          // eslint-disable-next-line no-console
          console.log('intercept request');
          if (keycloak.isTokenExpired(10)) {
            // eslint-disable-next-line no-console
            console.log('refreshing token');
            await keycloak
              .updateToken()
              .then(() => {
                // eslint-disable-next-line no-console
                console.log('token refreshed');
              })
              .catch(() => {
                client.logout();
              });
          }
          configuration.headers.Authorization = `Bearer ${keycloak.token}`; // eslint-disable-line no-param-reassign
          return configuration;
        },
        (error) => {
          Promise.reject(error);
        }
      );

      instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          Logger.error({
            token: keycloak.token,
            message: error.response.data,
            path: routeRef.current.url.pathname,
          });

          setAlertRef.current({
            type: 'api-error',
            errors: [
              {
                status: error.response.status,
                message: error.message,
                path: error.response.config.url,
              },
            ],
          });

          return Promise.reject(error);
        }
      );
      setAxiosInstance({ instance });
    }
    return () => {
      setAxiosInstance({});
    };
  }, [initialized]);

  return axiosInstance.instance;
};

export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

export const useFetchTeam = () => {
  const { keycloak, initialized } = useKeycloak();
  const axiosInstance = useAxios();
  const { team, setTeam, setTeamGroup } = useContext(TeamContext);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (initialized && team?.id) {
      loadGroup(team.id, axiosInstance, source, setTeamGroup, () => {});
    } else {
      setTeamGroup({});
    }
  }, [axiosInstance, initialized, team, setTeamGroup]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (initialized) {
      const {
        tokenParsed: { team_id: teamId },
      } = keycloak;

      loadTeam(teamId, axiosInstance, source, setTeam, setTeamGroup);
    }
    return () => {
      source.cancel('Cancelling request');
    };
  }, [axiosInstance, initialized, keycloak, setTeam]);
};

export const useFetchLocation = () => {
  const { keycloak, initialized } = useKeycloak();
  const axiosInstance = useAxios();
  const { setLocation } = useContext(LocationContext);
  const refDataPath = config.get('refDataPath');
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (initialized) {
      const {
        tokenParsed: { location_id: defaultlocationid },
      } = keycloak;
      const fetchData = async () => {
        try {
          if (defaultlocationid) {
            const response = await axiosInstance.get(
              `${refDataPath}/location?filter=id=eq.${defaultlocationid}`,
              {
                cancelToken: source.token,
              }
            );
            setLocation(response.data.data[0]);
          }
        } catch (error) {
          setLocation({});
        }
      };
      fetchData();
    }
    return () => {
      source.cancel('Cancelling request');
    };
  }, [axiosInstance, initialized, keycloak, setLocation]);
};

export const useFetchCurrentGroup = () => {
  const { keycloak, initialized } = useKeycloak();
  const axiosInstance = useAxios();
  const { currentGroup, setCurrentGroup, setGroupLoaded } = useContext(CurrentGroupContext);
  useEffect(() => {
    if (currentGroup) {
      setGroupLoaded(true);
      return () => {};
    }
    const source = axios.CancelToken.source();
    if (initialized && axiosInstance && setCurrentGroup) {
      const {
        tokenParsed: { team_id: teamid },
      } = keycloak;
      const fetchData = async () => {
        await loadGroup(teamid, axiosInstance, source, setCurrentGroup, setGroupLoaded);
      };
      fetchData();
    }
    return () => {
      source.cancel('Cancelling request');
    };
  }, [axiosInstance, initialized, currentGroup, setCurrentGroup, setGroupLoaded]);
};

export const useFetchGroups = () => {
  const { keycloak, initialized } = useKeycloak();
  const axiosInstance = useAxios();
  const { setGroups } = useContext(GroupsContext);
  const refDataPath = config.get('refDataPath');

  useEffect(() => {
    if (initialized && axiosInstance && setGroups) {
      const {
        tokenParsed: { team_id: teamid },
      } = keycloak;
      const fetchData = async () => {
        try {
          if (teamid) {
            const allUserGroups = keycloak.tokenParsed.groups.join(',');
            const response = await axiosInstance.get(`${refDataPath}/groups`, {
              params: {
                mode: 'dataOnly',
                select:
                  'id,displayname,code,grouptypeid,branchid,keycloakgrouppath,locationid,name,selfmanaged,teamid',
                filter: `keycloakgrouppath=in.(${allUserGroups})`,
              },
            });
            setGroups(response.data.data);
          }
        } catch (error) {
          setGroups([]);
        }
      };
      fetchData();
    }
  }, [axiosInstance, initialized, keycloak, setGroups]);
};

export const useFetchUser = () => {
  const { keycloak, initialized } = useKeycloak();
  const axiosInstance = useAxios();
  const {
    setUserGroupMember,
    setUserRole,
    setUserGroups,
    setIsHigherOfficer,
    setIsRCCUOfficer,
    setIsInterceptingOfficer,
  } = useContext(UserContext);

  const setupUserGroups = (data) => {
    setUserGroups({
      airPaxFrontLineGroups: data?.airPaxFrontLineGroups || [],
      roRoFrontLineGroups: data?.roRoFrontLineGroups || [],
      rccuGroups: data?.rccuGroups || [],
    });
  };

  const setupUserGroupMember = (data) => {
    if (
      (data?.airPaxFrontLineGroups?.length || data?.rccuGroups?.length) &&
      data?.roRoFrontLineGroups?.length
    ) {
      setUserGroupMember(GROUP_MEMBER.BOTH);
      return;
    }

    if (
      (data?.airPaxFrontLineGroups?.length || data?.rccuGroups?.length) &&
      !data?.roRoFrontLineGroups?.length
    ) {
      setUserGroupMember(GROUP_MEMBER.AIRPAX);
      return;
    }

    if (
      data.roRoFrontLineGroups?.length &&
      !data.airPaxFrontLineGroups?.length &&
      !data.rccuGroups?.length
    ) {
      setUserGroupMember(GROUP_MEMBER.RORO);
      return;
    }

    setUserGroupMember(GROUP_MEMBER.NONE);
  };

  const setupUserRole = (data) => {
    if (data.rccuOfficer) {
      setUserRole(ROLE.RCCU);
      return;
    }

    if (data.higherOfficer) {
      setUserRole(ROLE.HIGHER_OFFICER);
      return;
    }

    setUserRole(ROLE.INTERCEPTING_OFFICER);
  };

  const setupHigherOfficer = (data) => {
    setIsHigherOfficer(!!data.higherOfficer);
  };

  const setupRCCUOfficer = (data) => {
    setIsRCCUOfficer(!!data.rccuOfficer);
  };

  const setupInterceptingOfficer = (data) => {
    setIsInterceptingOfficer(!!data.interceptingOfficer);
  };

  useEffect(() => {
    if (initialized && axiosInstance && setUserGroupMember && setUserRole && setUserGroups) {
      const fetchUser = async () => {
        await axiosInstance.get('/cop-targeting-api/v2/users').then(({ data }) => {
          setupUserGroupMember(data);
          setupUserRole(data);
          setupUserGroups(data);
          setupHigherOfficer(data);
          setupRCCUOfficer(data);
          setupInterceptingOfficer(data);
        });
      };

      fetchUser();
    }
  }, [axiosInstance, initialized, keycloak]);
};

export default useIsMounted;
