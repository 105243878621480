// Global imports
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import FormioUtils from 'formiojs/utils';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';

// Local imports
import { useKeycloak } from '../../utils/KeycloakContext';
import ApplicationSpinner from '../../components/ApplicationSpinner';
import DisplayForm from '../../components/form/DisplayForm';
import apiHooks from '../../components/form/hooks';
import { formSubmitPath } from '../../utils/constants';
import { CurrentGroupContext } from '../../utils/CurrentGroupContext';
import { TeamContext } from '../../utils/TeamContext';
import { getRenderer, Renderers } from '../../utils/Form';
import { loadGroup, loadTeam, useAxios, useIsMounted } from '../../utils/hooks';

const FormPage = ({ processKey, version }) => {
  const { t } = useTranslation();
  const { submitForm } = apiHooks();
  const isMounted = useIsMounted();
  const navigation = useNavigation();
  const [repeat, setRepeat] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageHeading, setPageHeading] = useState();
  const [renderer, setRenderer] = useState(Renderers.FORM_IO);
  const [existingData, setExistingData] = useState({});
  const { keycloak } = useKeycloak();
  const { setCurrentGroup, setGroupLoaded } = useContext(CurrentGroupContext);
  const { setTeam, setTeamGroup } = useContext(TeamContext);

  const [form, setForm] = useState({
    isLoading: true,
    data: null,
  });

  const axiosInstance = useAxios();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    setRepeat(false);

    const fetchProcessName = async () => {
      if (axiosInstance) {
        try {
          const {
            data: { name },
          } = await axiosInstance.get(`/camunda/engine-rest/process-definition/key/${processKey}`, {
            cancelToken: source.token,
          });
          setPageHeading(name);
          document.title = `${name} - ${t('header.service-name')}`;
        } catch (e) {
          setPageHeading();
        }
      }
    };

    const fetchForm = async () => {
      if (axiosInstance) {
        try {
          const {
            data: { key },
          } = await axiosInstance.get(
            `/camunda/engine-rest/process-definition/key/${processKey}/startForm`,
            {
              cancelToken: source.token,
            }
          );
          const formRenderer = getRenderer(key);

          const copFormEndpoint = version
            ? `/copform/${key}/version/${version}`
            : `/copform/latest/${key}`;

          const formEndpoint =
            formRenderer === Renderers.REACT ? copFormEndpoint : `/form/name/${key}`;
          const { data } = await axiosInstance.get(formEndpoint);
          setRenderer(formRenderer);
          if (isMounted.current) {
            setForm({
              isLoading: false,
              data,
            });
          }
        } catch (e) {
          if (isMounted.current) {
            setForm({
              isLoading: false,
              data: null,
            });
          }
        }
      }
    };

    fetchForm();
    fetchProcessName();
    return () => {
      source.cancel('cancelling request');
    };
  }, [axiosInstance, processKey, setForm, isMounted, setSubmitting, repeat]);

  if (form.isLoading) {
    return <ApplicationSpinner />;
  }

  if (!form.data) {
    return null;
  }
  const businessKeyComponent = FormioUtils.getComponent(form.data.components, 'businessKey');
  const handleOnFailure = () => {
    setSubmitting(false);
  };
  const handleOnRepeat = () => {
    setSubmitting(false);
    setRepeat(true);
    window.scrollTo(0, 0);
  };
  /**
   * COP-9479. Handle issue with team being updated in the user profile.
   *
   * This method handles changes to the user's team and, if that's happened, then the
   * team context should updated appropriately.
   *
   * @param {Function} callback The function to call if the team has not changed.
   */
  const handleUserProfileSuccess = (callback) => {
    const source = axios.CancelToken.source();
    keycloak
      .loadUserInfo()
      .then(async (userInfo) => {
        const newTeamId = userInfo.team_id;
        const currentTeamId = keycloak.tokenParsed?.team_id;
        if (newTeamId !== currentTeamId) {
          // If the user's team has changed, clear out their currentGroup and log them out.
          localStorage.removeItem('currentGroup');
          await loadGroup(newTeamId, axiosInstance, source, setCurrentGroup, setGroupLoaded);
          await loadTeam(newTeamId, axiosInstance, source, setTeam, setTeamGroup);
        } else if (typeof callback === 'function') {
          // If the user's team has not changed, invoke the callback.
          callback();
        }
      })
      .catch(() => {
        // If there was an error with getting the user info, simply
        // invoke the callback.
        if (typeof callback === 'function') {
          callback();
        }
      });
  };

  return (
    <>
      {renderer !== Renderers.REACT && <h1 className="govuk-heading-l">{pageHeading}</h1>}
      <DisplayForm
        processKey={processKey}
        submitting={submitting}
        localStorageReference={`form-${form.data.name}`}
        form={form.data}
        setLoading={() => {
          setForm({
            isLoading: true,
            data: null,
          });
        }}
        handleOnCancel={async () => {
          await navigation.navigate('/forms');
        }}
        handleOnCustomEvent={({ type }) => {
          if (type === 'cancel-form') {
            navigation.navigate('/');
          }
        }}
        interpolateContext={{
          businessKey: businessKeyComponent ? businessKeyComponent.defaultValue : null,
        }}
        handleOnSubmit={(data, localStorageReference) => {
          setSubmitting(true);
          submitForm({
            submission: data,
            form: form.data,
            id: processKey,
            businessKey: businessKeyComponent ? businessKeyComponent.defaultValue : null,
            handleOnFailure,
            handleOnRepeat,
            handleOnSuccess: form.data.name === 'userProfile' ? handleUserProfileSuccess : null,
            submitPath: formSubmitPath,
            localStorageReference,
          });
        }}
        existingSubmission={{ data: existingData }}
        prepopulate={(data) => setExistingData(data)}
        handleOnSuccess={form.data.name === 'cop-userProfile' ? handleUserProfileSuccess : null}
      />
    </>
  );
};

FormPage.defaultProps = {
  version: null,
};

FormPage.propTypes = {
  processKey: PropTypes.string.isRequired,
  version: PropTypes.string,
};
export default FormPage;
