import React from 'react';

const NewAccountPage = () => {
  return (
    <>
      <h1>You do not have an active account</h1>
      <p>You need to create an account to use COP.</p>
      <a className="govuk-button" href="/forms/account">
        Create an account
      </a>
    </>
  );
};

export default NewAccountPage;
