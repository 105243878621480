const Transform = Object.freeze({
  Remove: Symbol('remove'),
  Rename: Symbol('rename'),
  Spread: Symbol('spread'),
  Map: Symbol('ref'),
  Parse: Symbol('parse'),
  Default: Symbol('default'),
  Consolidate: Symbol('consolidate'),
});

export default Transform;
