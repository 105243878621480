// Global imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@ukhomeoffice/cop-react-components';

const WatchForChangeAutocomplete = ({
  id,
  fieldId,
  onChange,
  source,
  value,
  item,
  watchedValue,
  readonly,
  ...attrs
}) => {
  const [currentWatchedValue, setCurrentWatchedValue] = useState(watchedValue);

  useEffect(() => {
    if (value !== undefined && !readonly && currentWatchedValue !== watchedValue) {
      setCurrentWatchedValue(watchedValue);
      onChange({ target: { name: fieldId, value: undefined } });
    }
  }, [watchedValue]);

  return (
    <Autocomplete
      id={id}
      fieldId={fieldId}
      source={source}
      value={value}
      item={item}
      onChange={onChange}
      showAllValues
      displayMenu="inline"
      returnFullData
      readonly={readonly}
      {...attrs}
    />
  );
};

WatchForChangeAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  watchedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  value: PropTypes.shape({}),
  readonly: PropTypes.bool,
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    format: PropTypes.string,
  }),
  source: PropTypes.func.isRequired,
};

WatchForChangeAutocomplete.defaultProps = {
  value: null,
  item: { value: 'id', label: 'name' },
  readonly: false,
};

export default WatchForChangeAutocomplete;
