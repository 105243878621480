const doRedirect = (data, form) => {
  const submissions = data.processContext?.submissions ? data.processContext.submissions : 0;

  if (!form.cya?.path_for_redirect) {
    return false;
  }

  return (
    (form.cya?.path_for_redirect && !form.cya?.confirm_on_first_submit) ||
    (form.cya?.path_for_redirect && form.cya?.confirm_on_first_submit && submissions >= 1)
  );
};

export default doRedirect;
