// Local imports
import checkSealNumber from './checkSealNumber';
import getSourceData from '../utils/getSourceData';

const sealNumberUnique = async (value, config, component, axiosInstance) => {
  const result = await checkSealNumber(value, axiosInstance, config);
  return result
    ? {
        message: result,
        component,
      }
    : undefined;
};

const allSealNumbersUnique = async (value, config, component, axiosInstance, actionType) => {
  // Only trigger when user is moving on from page.
  // Should not trigger when user presses a 'Change' button on a summary card.
  if (actionType === 'saveAndNavigate') {
    return [];
  }
  const results = [];
  /* eslint-disable */
  for (const item of value) {
    const result = await checkSealNumber(item.sealNumber, axiosInstance, config);
    if (result) {
      results.push({
        message: result,
        entryId: item.id,
        showFor: component.id,
        raisedBy: component.id,
        component: {
          ...component,
          id: '',
        },
      });
    }
  }
  /* eslint-enable */
  return results;
};

const errorIfTruthy = (value, config, component) => {
  return value
    ? {
        message: config.message,
        component,
      }
    : false;
};

const errorIfFalsy = (value, config, component) => {
  return value
    ? false
    : {
        message: config.message,
        component,
      };
};

const checkEmail = (value, config, component) => {
  const result = config.additionalValues.find((obj) => obj.type === 'email');
  return value === result.value
    ? {
        message: config.message,
        component,
      }
    : false;
};

const functions = {
  allSealNumbersUnique,
  checkEmail,
  errorIfTruthy,
  errorIfFalsy,
  sealNumberUnique,
};

const resolveAdditionalValues = (values, payload) => {
  const additionalValues = [];
  values.forEach((value) => {
    const addlValue = { ...value };
    addlValue.value = getSourceData(payload, value.value);
    additionalValues.push(addlValue);
  });
  return additionalValues;
};

/**
 * customValidation - call custom validation routine
 * @param value Value to be validated
 * @param config Error checking configuration
 * @param component Form Component
 * @param axiosInstance Instance for API calls
 * @param actionType The type of action that triggered the validation.
 * @returns {undefined|*}
 */
const customValidation = (value, config, component, axiosInstance, actionType = null) => {
  const fn = functions[config.function];
  if (typeof fn === 'function') {
    return fn(value, config, component, axiosInstance, actionType);
  }
  return undefined;
};

/**
 * checkRunWhen - check if custom validation should run
 * @param runWhen configuration for the check to run
 * @param payload form data payload
 * @returns {bool} true if validation should run else false
 */
const checkRunWhen = (runWhen, payload, pageId) => {
  if (!runWhen || !payload) {
    return false;
  }
  if (runWhen.isNotPresent && payload[runWhen.isNotPresent]) {
    return false;
  }
  if (runWhen.pageIdIn && pageId && !runWhen.pageIdIn.includes(pageId)) {
    return false;
  }
  return true;
};

/**
 * run validation on a component with a given value
 * @param value Value to be validated
 * @param component Form component
 * @param payload Form data
 * @param actionType The type of action that triggered the validation.
 * @returns {Promise[]}
 */
const runCustomComponentValidation = (
  value,
  component,
  axiosInstance,
  payload,
  pageId,
  actionType = null
) => {
  const promises = [];
  // We only care when we have a value
  if (value != null) {
    if (Array.isArray(component.customValidation)) {
      component.customValidation.forEach((config) => {
        const resolvedConfig = { ...config };
        if (Array.isArray(config.additionalValues)) {
          resolvedConfig.additionalValues = resolveAdditionalValues(
            config.additionalValues,
            payload
          );
        }
        if (resolvedConfig.runWhen) {
          if (!checkRunWhen(resolvedConfig.runWhen, payload, pageId)) {
            return;
          }
        }
        promises.push(
          Promise.resolve(
            customValidation(value, resolvedConfig, component, axiosInstance, actionType)
          )
        );
      });
    }
  }
  return promises;
};

export default runCustomComponentValidation;
