// Global imports.
import { Utils } from '@ukhomeoffice/cop-react-form-renderer';
import getCYARowsForContainer from '@ukhomeoffice/cop-react-form-renderer/dist/utils/CheckYourAnswers/getCYARowsForContainer';

// Local imports.
import applyCustomConfig from './applyCustomConfig';
import { DefaultComponents } from '../../Common/CompositeComponents';

const getCYARowsForComposite = (component, page, onAction) => {
  const defaults = DefaultComponents[component.type];
  if (!defaults) {
    return [];
  }
  const container = {
    ...component,
    components: component.customConfig
      ? applyCustomConfig(component.customConfig, defaults)
      : defaults,
  };
  let data = page.formData;
  if (page.collection) {
    const activeIndex = Utils.CollectionPage.getActiveIndex(page.collection.name, page.formData);
    if (activeIndex !== null) {
      data = {
        ...page.formData,
        ...page.formData[page.collection.name]?.[activeIndex],
      };
    }
  }
  const result = getCYARowsForContainer(page, container, data?.[container.fieldId], onAction, null);
  return result;
};

export default getCYARowsForComposite;
