/**
 * Attempts to get existing data for a named form from the process context.
 * @param {object} processContext The process context.
 * @param {string} formName The name of the form to get the existing data for.
 * @returns A JSON object representing the data for the named form, or an empty object.
 */
const getExistingFormData = (processContext, formName) => {
  if (processContext && processContext[formName]) {
    const formJSON = processContext[formName];
    if (typeof formJSON === 'string') {
      try {
        return JSON.parse(formJSON);
      } catch {
        // If this is not a JSON string, return an empty object.
        return {};
      }
    }
    return formJSON;
  }
  return {};
};

export default getExistingFormData;
