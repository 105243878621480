// Global imports.
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Container } from '@ukhomeoffice/cop-react-form-renderer';

// Local imports.
import applyCustomConfig from '../form/utils/applyCustomConfig';

const CompositeComponent = ({
  id,
  fieldId,
  onChange,
  onTopLevelChange,
  value,
  formData,
  fullPath,
  defaultComponents,
  customConfig,
  wrap,
  required,
  readonly,
}) => {
  const [components, setComponents] = useState([]);

  useEffect(() => {
    const newComponents = applyCustomConfig(customConfig, defaultComponents).map((c) => ({
      ...c,
      full_path: `${fieldId}.${c.fieldId}`,
    }));
    setComponents(newComponents);
  }, [customConfig, defaultComponents, setComponents]);

  return (
    <Container
      container={{ id, fieldId, components, full_path: fullPath, readonly }}
      onChange={onChange}
      onTopLevelChange={onTopLevelChange}
      value={value}
      formData={formData}
      wrap={wrap}
      required={required}
    />
  );
};

CompositeComponent.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onTopLevelChange: PropTypes.func,
  value: PropTypes.shape({}),
  formData: PropTypes.shape({}),
  fullPath: PropTypes.string,
  defaultComponents: PropTypes.arrayOf(PropTypes.shape({})),
  customConfig: PropTypes.shape({}),
  required: PropTypes.bool,
  wrap: PropTypes.bool,
  readonly: PropTypes.bool,
};

CompositeComponent.defaultProps = {
  onChange: () => {},
  onTopLevelChange: () => {},
  value: {},
  formData: {},
  fullPath: undefined,
  defaultComponents: [],
  customConfig: {},
  required: true,
  wrap: true,
  readonly: false,
};

export default CompositeComponent;
