/**
 * Gets the appropriate axios function to use for this submission.
 * @param {string} type The type of submission.
 * @param {object} payload The raw payload for submission.
 * @param {object} axiosInstance The axios instance to use.
 * @returns PUT when this is an update; POST otherwise.
 */
const getSubmissionFunction = (type, payload, axiosInstance) => {
  if (type !== 'submit' && payload.id) {
    return axiosInstance.put;
  }
  return axiosInstance.post;
};

export default getSubmissionFunction;
