// Global imports
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PropTypes from 'prop-types';
import { Checkboxes } from '@ukhomeoffice/cop-react-components';
import { useState } from 'react';

const ReferralCheckboxes = ({
  id,
  fieldId,
  required,
  readOnly,
  label,
  value,
  onChange,
  formData,
  ...attrs
}) => {
  const people = [];
  const [checkboxOptions, setCheckboxOptions] = useState([]);

  if (formData.people) {
    dayjs.extend(customParseFormat);
    let checkboxLabel;
    let fullName;

    // Extract people into people array with DOB in Label
    formData.people.forEach((person) => {
      if (
        person.documents &&
        person.documents[0].surname &&
        person.documents[0].givenNames &&
        person.documents[0].dateOfBirth
      ) {
        fullName = `${person.documents[0].givenNames} ${person.documents[0].surname}`;

        checkboxLabel = `${fullName} (${dayjs(person.documents[0].dateOfBirth, 'D-M-YYYY').format(
          'D MMM YYYY'
        )})`;
      } else if (person.claimedGivenNames && person.claimedSurname && person.estimatedDob) {
        fullName = `${person.claimedGivenNames} ${person.claimedSurname}`;
        checkboxLabel = `${fullName} (${dayjs(person.estimatedDob, 'D-M-YYYY').format(
          'D MMM YYYY'
        )})`;
      }

      if (checkboxLabel && fullName) {
        people.push({
          label: checkboxLabel,
          value: fullName,
          id: person.id,
        });
      }
    });

    // When multiple people, show checkboxes or people and exclusive 'all' option
    if (formData.people.length > 1 && checkboxOptions.length === 0) {
      const extraOptions = [
        'or',
        {
          label: 'All',
          value: 'all',
          behaviour: 'exclusive',
        },
      ];

      setCheckboxOptions(people.concat(extraOptions));
    }

    const targetValue = [
      {
        label: people[0].label,
        value: people[0].value,
        id: people[0].id,
      },
    ];

    // when one person, only show one checbox pre-selected
    if (formData.people?.length === 1 && people.length === 1 && checkboxOptions.length === 0) {
      setCheckboxOptions(people);

      if (value !== targetValue) {
        onChange({
          target: {
            name: id,
            label: targetValue.label,
            value: targetValue,
            component: label,
          },
        });
      }
    }
  }

  return (
    <Checkboxes
      id={id}
      fieldId={fieldId}
      options={checkboxOptions}
      value={value}
      onChange={onChange}
      returnFullData
      readonly={readOnly}
      {...attrs}
    />
  );
};

ReferralCheckboxes.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  formData: PropTypes.shape({
    people: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          documents: PropTypes.arrayOf(
            PropTypes.shape({
              givenNames: PropTypes.string.isRequired,
              surname: PropTypes.string.isRequired,
              dateOfBirth: PropTypes.string.isRequired,
            })
          ),
        }),
        PropTypes.shape({
          claimedGivenNames: PropTypes.string.isRequired,
          claimedSurname: PropTypes.string.isRequired,
          estimatedDob: PropTypes.string.isRequired,
        }),
      ])
    ),
  }).isRequired,
};

ReferralCheckboxes.defaultProps = {
  value: null,
  label: null,
  readOnly: false,
  required: false,
};

export default ReferralCheckboxes;
