// Global imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Select } from '@ukhomeoffice/cop-react-components';
import { Utils } from '@ukhomeoffice/cop-react-form-renderer';

// Local imports
import { useAxios } from '../../utils/hooks';
import FixedValue from './FixedValue';

const LockupSelect = ({ id, fieldId, onChange, port, value, config, formData, ...attrs }) => {
  const [lockups, setLockups] = useState([]);
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchLockups = async () => {
      try {
        const api = '/camunda/data-service/station-storage-location';
        let response = await axiosInstance.get(port ? `${api}/${port}` : api);
        // get whole list if no data
        if (!response?.data.length) {
          response = await axiosInstance.get(api);
        }
        const items = response?.data;
        const options = items.map((item) => {
          return {
            value: item.station.value,
            label: item.station.description,
          };
        });
        setLockups(options);
      } catch {
        setLockups([]);
      }
    };
    if (axiosInstance) {
      fetchLockups();
    }
  }, [axiosInstance, port]);

  // We shouldn't render anything if there's only one possible lockup,
  // but the value should be set so we used a FixedValue here.
  if (lockups.length === 1) {
    if (attrs.readonly) {
      return null;
    }
    return Utils.Component.wrap(
      { label: '', required: true },
      // The 'hidden' flag is added here so that we can pick it
      // up in the form and hide the lockup value from the CYA
      // when there's only one option.
      <FixedValue fieldId={fieldId} onChange={onChange} value={{ ...lockups[0], hidden: true }} />
    );
  }

  // If there's more than one possible lockup, then we show the normal
  // Select, but disable it if the current item isn't the first. This
  // is because the lockup should be the same for all items, so it's
  // only editable when adding the first item.
  return Utils.Component.wrap(
    { ...config },
    <Select
      id={id}
      fieldId={fieldId}
      options={lockups}
      onChange={onChange}
      type="select"
      value={value}
      returnFullData
      {...attrs}
      disabled={formData.itemsActiveId !== formData.items?.[0].id}
    />
  );
};

LockupSelect.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  port: PropTypes.string,
  value: PropTypes.string,
  config: PropTypes.shape({}).isRequired,
  formData: PropTypes.shape({
    itemsActiveId: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

LockupSelect.defaultProps = {
  port: '',
  value: null,
};

export default LockupSelect;
