/**
 * Applies custom configurations to an array of default components,
 * returning an array of the resulting components.
 *
 * This function is used to allow some customisation of the
 * common components. It will not allow changing of id, fieldId
 * or type as this would result in the component's core functionality
 * changing or breaking.
 *
 * @param {object} customConfig The custom configuration for the components.
 * @param {Array} defaultComponents An array of default components.
 * @returns An array of customised components.
 */
const applyCustomConfig = (customConfig, defaultComponents) => {
  return defaultComponents.map((component) => {
    if (!customConfig?.[component.fieldId]) {
      return component;
    }
    return {
      ...component,
      ...customConfig[component.fieldId],
      id: component.id,
      fieldId: component.fieldId,
      type: component.type,
    };
  });
};

export default applyCustomConfig;
