import { camundaRoot, formSubmitPath } from '../constants';
import getTaskId from './getTaskId';

export const submitTaskUrl = (taskId) => {
  return `${camundaRoot}/task/${taskId}/submit-form`;
};

export const submitProcessUrl = (processId, formName) => {
  return `${camundaRoot}/process-instance/${processId}/variables/${formName}?deserializeValue=false`;
};

export const submitFormUrl = (processKey) => {
  return `${camundaRoot}/${formSubmitPath}/${processKey}/submit-form`;
};

export const startUrl = (formName) => {
  return `${camundaRoot}/process-definition/key/${formName}/start`;
};

/**
 * Gets the appropriate URL for submitting a form.
 * @param {string} type The type of submission.
 * @param {object} payload The raw payload for submission.
 * @param {string} formName The name of the form to be submitted.
 * @param {object} axiosInstance The axios instance to use.
 * @param {string} processKey The process key to use.
 * @returns The URL for submitting a form.
 */
const getSubmissionUrl = async (type, payload, formName, axiosInstance, processKey) => {
  const { id, businessKey } = payload;
  if (id) {
    if (type === 'submit') {
      const taskId = await getTaskId(id, businessKey, axiosInstance);
      if (taskId) {
        return submitTaskUrl(taskId);
      }
    } else {
      return submitProcessUrl(id, formName);
    }
  } else if (type === 'start') {
    return startUrl(formName);
  }
  // Default URL.
  return submitFormUrl(processKey);
};

export default getSubmissionUrl;
