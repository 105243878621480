// Global imports.
import { Utils } from '@ukhomeoffice/cop-react-form-renderer';

// Local imports.
import applyCustomConfig from '../utils/applyCustomConfig';
import { DefaultComponents } from '../../Common/CompositeComponents';

const validateCompositeComponent = (component, data) => {
  const defaults = DefaultComponents[component.type];
  if (!defaults) {
    return [];
  }
  const container = {
    ...component,
    components: component.customConfig
      ? applyCustomConfig(component.customConfig, defaults)
      : defaults,
  };
  return Utils.Validate.container(container, data, data);
};

export default validateCompositeComponent;
