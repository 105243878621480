import { v4 as uuidv4 } from 'uuid';

/**
 * Generates a new GUID that will be used as a unique identifier
 * for a form throughout its lifetime of amendments after submission
 * @returns The newly-generated GUID
 */
const generateFormInstanceId = () => {
  return uuidv4();
};

export default generateFormInstanceId;
