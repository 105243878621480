// Global imports
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const FixedValue = ({ fieldId, onChange, value }) => {
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({
        target: {
          name: fieldId,
          value,
        },
      });
    }
  }, [value]);

  return <div id={fieldId} style={{ display: 'none' }} />;
};

FixedValue.propTypes = {
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

FixedValue.defaultProps = {
  value: null,
};

export default FixedValue;
