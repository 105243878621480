import checkForAdultWithValidId from './checkForAdultWithValidId';
import clearIfCategoryChanged from './clearIfCategoryChangedAction';
import ensureItemPhotosExist from './ensureItemPhotosExist';
import onAddDocument from './onAddDocument';
import preprocessForEAB2Submit from './preprocessForEAB2Submit';

const actions = {
  checkForAdultWithValidId,
  clearIfCategoryChanged,
  ensureItemPhotosExist,
  onAddDocument,
  preprocessForEAB2Submit,
};

/**
 *
 * @param {*} formData full form data - has to be updated
 * @param {*} patch contains only the current change to the form data
 * @param {*} data form data without the current change, can be used to tell what changed
 * @param {*} action the action to apply
 * @returns formData and patch, either unchanged or updated in the specified action
 * The custom action must return two objects called formData and patch
 */
const performAction = (formData, patch, data, action) => {
  const toPerform = actions[action?.type];
  if (typeof toPerform === 'function') {
    return toPerform(formData, patch, data);
  }
  return { formData, patch };
};

export default performAction;
