// Global imports
import React from 'react';
import PropTypes from 'prop-types';
import { NotFoundBoundary, useCurrentRoute, useNavigation } from 'react-navi';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '../../utils/KeycloakContext';

// Local imports
import Footer from '../Footer';
import Header from '../Header';
import Logger from '../../utils/logger';
import AlertBanner from '../alert/AlertBanner';
import { AlertContextProvider } from '../../utils/AlertContext';
import { CACHED_TAB, PATHS } from '../../utils/constants';
import TimeoutModal from './timeoutModal';
import NotFound from '../NotFound';
import PhaseBanner from '../PhaseBanner';
import './index.scss';
import INVALID_BACK_BUTTON_PATHS from './invalidBackButtonPaths';

const ErrorFallback = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <div
      className="hods-width-container govuk-error-summary govuk-!-margin-top-5"
      aria-labelledby="error-summary-title"
      role="alert"
      tabIndex="-1"
      data-module="govuk-error-summary"
    >
      <h2 id="error-summary-title" className="govuk-error-summary__title">
        {t('error.page.title')}
      </h2>
      <div className="govuk-error-summary__body">
        <button
          type="button"
          className="govuk-button govuk-button--warning"
          data-module="govuk-button"
          onClick={resetErrorBoundary}
        >
          {t('error.page.retry')}
        </button>
      </div>
    </div>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

const renderBackButton = (path) => {
  return !Object.values(INVALID_BACK_BUTTON_PATHS).some((invalidPath) => {
    const checkingRoot = invalidPath === INVALID_BACK_BUTTON_PATHS.ROOT;

    return checkingRoot ? path === invalidPath : path.includes(invalidPath);
  });
};

const Layout = ({ children }) => {
  const { keycloak } = useKeycloak();
  const route = useCurrentRoute();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const handleLogger = (error, componentStack) => {
    Logger.error({
      token: keycloak.token,
      message: error.message,
      path: route.url.pathname,
      componentStack,
    });
  };

  const NotFoundCallBack = (error, componentStack) => {
    handleLogger(error, componentStack);
    return <NotFound />;
  };

  // 'timeout', sets duration (in milliseconds) when it runs out user becomes 'idle' and gets logged out.
  // 'promptBeforeIdle', sets duration between being prompted (Modal appears) and becoming 'idle' and being logged out.
  const keycloakLogout = () => {
    return <TimeoutModal timeout={1_800_000} promptBeforeIdle={299_000} />;
  };

  return (
    <div className={PATHS.TARGET_PATHS().includes(route.url.pathname) ? 'targets' : ''}>
      {keycloakLogout()}
      <Header />
      <PhaseBanner />
      <div className="app-container hods-width-container">
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleLogger}>
          <NotFoundBoundary render={NotFoundCallBack}>
            {renderBackButton(route.url.pathname) ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="/"
                id="back-link"
                onClick={async (e) => {
                  localStorage.removeItem(CACHED_TAB);
                  e.preventDefault();
                  await navigation.goBack();
                }}
                className="govuk-back-link"
              >
                {t('back')}
              </a>
            ) : null}
            <main
              className="govuk-main-wrapper govuk-main-wrapper--auto-spacing govuk-!-padding-top-3"
              role="main"
              id="main-content"
            >
              <AlertContextProvider>
                <AlertBanner />
                {children}
              </AlertContextProvider>
            </main>
          </NotFoundBoundary>
        </ErrorBoundary>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
