import { Utils } from '@ukhomeoffice/cop-react-form-renderer';

/**
 * Removes properties that should not be on a person when a document is added
 * @param {*} formData full form data - has to be updated
 * @param {*} patch  contains only the current change to the form data
 * @returns the formData and patch with updated people
 */
const onAddDocument = (formData, patch) => {
  const activeIndex = Utils.CollectionPage.getActiveIndex('people', formData);
  if (activeIndex !== null && formData.people && patch.people) {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    const patchCopy = JSON.parse(JSON.stringify(patch));
    delete formDataCopy.people[activeIndex].hasIdentityDocuments;
    delete patchCopy.people[activeIndex].hasIdentityDocuments;
    return { formData: formDataCopy, patch: patchCopy };
  }
  return { formData, patch };
};

export default onAddDocument;
