const pendingStaffDetailsPagesToFields = {
  'line-manager-email': 'linemanagerEmail',
  'delegate-email': 'delegateEmail',
};

const isPendingStaffDetailsPage = (pageId) => {
  return pageId in pendingStaffDetailsPagesToFields;
};

const updatePendingStaffDetail = (page) => {
  const pageId = page?.id;
  const formData = page?.formData;
  const fieldId = pendingStaffDetailsPagesToFields[pageId];
  const activeUserDetails = formData?.extendedStaffDetailsContext;

  if (pageId && formData && fieldId && activeUserDetails) {
    const pendingValue = formData[fieldId];
    const activeValue = activeUserDetails[fieldId];

    if (pendingValue !== activeValue) {
      formData[fieldId] = activeValue;
    }
  }
};

export { isPendingStaffDetailsPage, updatePendingStaffDetail };
