import { useKeycloak } from './KeycloakContext';
import { clearSecureStorageAndCurrentGroup } from './StorageHelper';

// TODO refactor rest of keycloak calls across cop-ui into the auth client to abstract logic to a single location
const authClient = () => {
  const { keycloak } = useKeycloak();

  const logout = () => {
    clearSecureStorageAndCurrentGroup();
    keycloak.logout({
      redirectUri: window.location.origin.toString(),
    });
  };

  return {
    logout,
  };
};

export default authClient;
