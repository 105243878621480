import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Utils } from '@ukhomeoffice/cop-react-form-renderer';

/**
 * Checks to see if a valid document has been entered
 * that proves the active person is an adult
 * @param {*} formData full form data
 * @returns
 *  - 'yes' if valid ID present proving over 18
 *  - 'no' if valid ID present proving under 18
 *  - 'unknown' if no valid ID
 */
const doCheck = (formData) => {
  // Check if there are people
  if (!formData.people) {
    return 'no';
  }
  // Check if active person has documents
  const activeIndex = Utils.CollectionPage.getActiveIndex('people', formData);
  const activePerson = formData.people[activeIndex];
  if (!activePerson.documents || activePerson.documents.length === 0) {
    return 'unknown';
  }

  // Check for valid doc
  const validDocs = activePerson.documents.filter((doc) => {
    return doc.documentValidity === 'valid';
  });
  if (validDocs.length === 0) {
    return 'unknown';
  }

  // Check each document to see if the date shows they are over 18
  dayjs.extend(customParseFormat);
  const result = validDocs.some((doc) => {
    const cutOff = dayjs().subtract(18, 'year');
    return dayjs(doc.dateOfBirth, 'D-M-YYYY').isBefore(dayjs(cutOff));
  });
  if (result) {
    return 'yes';
  }
  return 'no';
};

const checkForAdultWithValidId = (formData, patch) => {
  const result = doCheck(formData);

  const peopleUpdate = formData.people;
  if (peopleUpdate) {
    const activeIndex = Utils.CollectionPage.getActiveIndex('people', formData);
    peopleUpdate[activeIndex].activePersonAdult = result;
  }

  return { formData: { ...formData, people: peopleUpdate, activePersonAdult: result }, patch };
};

export default checkForAdultWithValidId;
