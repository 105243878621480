/**
 * Receives account information from the user and updates user attribute.
 * @param {string} gradeId The pre-existing gradeId of a user.
 * @returns The 'Are you a civil servant' answer within 'Your Profile'.
 */

const defaultCivilServantStatus = (gradeId) => {
  if (gradeId === '073b92f8-7208-4026-a090-906c276079f1') {
    return 'no-contractor';
  }
  if (gradeId === 'c27cf914-0b97-4c4e-bb2d-2ae4b886baa5') {
    return 'no-agency';
  }
  return 'yes';
};

export default defaultCivilServantStatus;
