import { camundaRoot } from '../constants';

export const TASK_URL_PREFIX = `${camundaRoot}/task?processInstanceBusinessKey=`;

/**
 * Gets a task id for a given processId.
 *
 * @param {string} processId The id of the process to find the task for.
 * @param {string} businessKey The business key to get all of the tasks for.
 * @param {object} axiosInstance The axios instance to use.
 * @returns The id of the found task, otherwise undefined.
 */
const getTaskId = async (processId, businessKey, axiosInstance) => {
  const taskUrl = `${TASK_URL_PREFIX}${businessKey}`;
  const { data } = await axiosInstance.get(taskUrl);
  const tasks = data || [];
  const foundTask = tasks.find((task) => task.processInstanceId === processId);
  return foundTask ? foundTask.id : undefined;
};

export default getTaskId;
