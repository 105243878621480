import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BLACK } from 'govuk-colours';

const LoadingSpinner = ({
  fieldId,
  value,
  mainText,
  subText,
  formData,
  onAction,
  nextPageField,
  submitting,
}) => {
  const stillUploading = () => {
    return formData.meta.documents?.some((document) => document.url === '');
  };

  useEffect(() => {
    if (formData.meta && !stillUploading() && !submitting && value) {
      // Remove trigger from form data
      const nextPage =
        formData[nextPageField] === 'saveAndReturn' ? undefined : formData[nextPageField];
      onAction({
        type: nextPage === undefined ? 'saveAndReturn' : 'saveAndNavigate',
        page: nextPage,
        addToFormData: {
          field: fieldId,
          value: false,
        },
      });
    }
  }, [formData.meta, submitting]);

  return (
    <>
      {value && (
        <SpinnerContainer>
          <Spinner />
          <SpinnerText>{mainText}</SpinnerText>
          {subText && <SpinnerSubText>{subText}</SpinnerSubText>}
        </SpinnerContainer>
      )}
    </>
  );
};

const SpinnerContainer = styled.div`
  height: 210px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #ffffff;
`;
const Spinner = styled.div`
  margin-left: auto;
  margin-right: auto;
  border: 12px solid #dee0e2;
  border-radius: 50%;
  border-top-color: #005ea5;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerText = styled.div`
  line-height: 2;
  text-align: center;
  color: ${BLACK};
  font-weight: bold;
  font-size: 1.5rem;
`;

const SpinnerSubText = styled.div`
  line-height: 2;
  text-align: center;
  color: ${BLACK};
  font-size: 1.2rem;
`;

LoadingSpinner.defaultProps = {
  mainText: 'loading',
  subText: null,
  formData: {},
  value: false,
};
LoadingSpinner.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  nextPageField: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    meta: PropTypes.shape({ documents: PropTypes.arrayOf(PropTypes.shape()) }),
  }),
  submitting: PropTypes.bool.isRequired,
};

export default LoadingSpinner;
