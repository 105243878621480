import { biometricResidenceCardComponents } from './biometricResidenceCardComponents';
import { nationalIdentityCardComponents } from './nationalIdentityCardComponents';
import { nonUkAddressComponents } from './nonUkAddressComponents';
import { otherIdentityDocumentComponents } from './otherIdentityDocumentComponents';
import { passportComponents } from './passportComponents';
import { ukAddressComponents } from './ukAddressComponents';

export const DefaultComponents = {
  biometricResidenceCard: biometricResidenceCardComponents,
  nationalIdentityCard: nationalIdentityCardComponents,
  nonUkAddress: nonUkAddressComponents,
  otherIdentityDocument: otherIdentityDocumentComponents,
  passport: passportComponents,
  ukAddress: ukAddressComponents,
  addressLookup: ukAddressComponents,
};

export const CompositeTypes = [
  'biometricResidenceCard',
  'nationalIdentityCard',
  'nonUkAddress',
  'otherIdentityDocument',
  'passport',
  'ukAddress',
  'addressLookup',
];
