// Global imports
import Alert from '@ukhomeoffice/cop-react-components/dist/Alert';
import Utils from '@ukhomeoffice/cop-react-form-renderer/dist/utils';

// Local imports
import CompositeComponent from '../../components/Common/CompositeComponent';
import { CompositeTypes, DefaultComponents } from '../../components/Common/CompositeComponents';
import EpmsOfficerEmailsAutocomplete from '../../components/Eab/EpmsOfficerEmailsAutocomplete';
import FixedValue from '../../components/Eab/FixedValue';
import LockupSelect from '../../components/Eab/LockupSelect';
import StorageLocationSelect from '../../components/Eab/StorageLocationSelect';
import UnitOfMeasureSelect from '../../components/Eab/UnitOfMeasureSelect';
import TargetModeForPortAutocomplete from '../../components/Eab/TargetModeForPortAutocomplete';
import WatchForChangeAutocomplete from '../../components/Eab/WatchForChangeAutocomplete';
import AlternativeFormCYA from '../../components/form/utils/AlternativeFormCYA';
import LoadingSpinner from '../../components/Eab/LoadingSpinner';
import ReferralCheckboxes from '../../components/Eab/ReferralCheckboxes';
import AddressLookup from '../../components/Common/AddressLookup/AddressLookup';
import WhatHappenedRadios from '../../components/Eab/WhatHappenedRadios';

const onGetComponent = (config) => {
  const activeItem = config.formData?.items?.find((i) => i.id === config.formData.itemsActiveId);
  switch (config.type) {
    case 'epmsConfirmationAlert':
      return (
        <Alert heading="A new entry has been created on ePMS" classModifiers="success">
          COP ref: {config.formData?.businessKey}
        </Alert>
      );
    case 'epmsOfficerEmails':
      return Utils.Component.wrap(
        config,
        <EpmsOfficerEmailsAutocomplete
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          onChange={config.onChange}
          agency={Utils.interpolateString(config.agency, config.formData)}
          disabled={config.disabled}
          value={config.formData[config.fieldId]}
          item={config.item}
          showAllValues={config.showAllValues}
          placeholder={config.placeholder}
        />
      );
    case 'epmsLockup':
      return (
        <LockupSelect
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          onChange={config.onTopLevelChange}
          port={config.formData.port?.value}
          disabled={config.disabled}
          value={config.formData[config.fieldId]}
          config={config}
          formData={config.formData}
        />
      );
    case 'epmsStorage':
      return Utils.Component.wrap(
        config,
        <StorageLocationSelect
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          onChange={config.onChange}
          port={config.formData.port?.value}
          lockup={config.formData.lockup?.value}
          disabled={config.disabled}
          value={config.formData[config.fieldId]}
        />
      );
    case 'fixedValue':
      return Utils.Component.wrap(
        { ...config, required: true },
        <FixedValue fieldId={config.fieldId} onChange={config.onChange} value={config.fixedValue} />
      );
    case 'whatHappenedRadios':
      if (config.readonly) {
        return (
          <WhatHappenedRadios
            id={config.id}
            fieldId={config.fieldId}
            options={config.data.options}
            onChange={config.onChange}
            value={config.formData[config.fieldId]}
            formData={config.formData}
            readonly={config.readonly}
          />
        );
      }
      return Utils.Component.wrap(
        config,
        <WhatHappenedRadios
          id={config.id}
          fieldId={config.fieldId}
          options={config.data.options}
          onChange={config.onChange}
          value={config.formData[config.fieldId]}
          formData={config.formData}
          readonly={config.readonly}
        />
      );
    case 'addressLookup': {
      const addressLookup = (
        <AddressLookup
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          label={config.label}
          subhint={config.subhint}
          readonly={config.readonly}
          value={config.value || config.formData[config.fieldId]}
          onChange={config.onChange}
          formData={config.formData}
        />
      );
      if (config.readonly) {
        return addressLookup;
      }
      return Utils.Component.wrap(config, addressLookup);
    }
    case CompositeTypes.includes(config.type) && config.type:
      if (config.readonly) {
        return (
          <CompositeComponent
            id={config.id}
            fieldId={config.fieldId}
            onChange={config.onChange}
            onTopLevelChange={null}
            value={config.value || config.formData[config.fieldId]}
            formData={config.formData}
            defaultComponents={DefaultComponents[config.type]}
            customConfig={config.customConfig || []}
            required={config.required}
            readonly={config.readonly}
          />
        );
      }
      return Utils.Component.wrap(
        config,
        <CompositeComponent
          id={config.id}
          fieldId={config.fieldId}
          onChange={config.onChange}
          onTopLevelChange={null}
          value={config.value || config.formData[config.fieldId]}
          formData={config.formData}
          fullPath={config.full_path}
          defaultComponents={DefaultComponents[config.type]}
          customConfig={config.customConfig || []}
          required={config.required}
          readonly={config.readonly}
        />
      );
    case 'unitOfMeasure':
      return Utils.Component.wrap(
        config,
        <UnitOfMeasureSelect
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          onChange={config.onChange}
          disabled={config.disabled}
          value={activeItem?.[config.fieldId] || config.formData[config.fieldId]}
          item={config.item}
          acceptedUnits={activeItem?.itemSubCategory?.unitarray || []}
        />
      );
    case 'targetModeForPort':
      return Utils.Component.wrap(
        config,
        <TargetModeForPortAutocomplete
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          onChange={config.onChange}
          port={config.formData.port}
          lockup={config.formData.lockup?.value}
          disabled={config.disabled}
          value={config.formData[config.fieldId]}
          placeholder={config.placeholder}
          custom_errors={config.custom_errors}
        />
      );
    case 'watchForChangeAutocomplete': {
      const { watchedField, ...cleanedConfig } = config;
      const watchForChange = (
        <WatchForChangeAutocomplete
          id={config.id}
          source={Utils.Data.getAutocompleteSource(config)}
          fieldId={config.fieldId}
          required={config.required}
          onChange={config.onChange}
          disabled={config.disabled}
          readonly={config.readonly}
          watchedValue={activeItem?.[config.watchedField] || config.formData[config.watchedField]}
          value={config.value || activeItem?.[config.fieldId] || config.formData[config.fieldId]}
          placeholder={config.placeholder}
          custom_errors={config.custom_errors}
        />
      );
      if (config.readonly) {
        return watchForChange;
      }
      return Utils.Component.wrap(cleanedConfig, watchForChange);
    }
    case 'alternativeFormCYA': {
      return Utils.Component.wrap(config, <AlternativeFormCYA config={config} />);
    }
    case 'loadingSpinner': {
      return (
        <LoadingSpinner
          fieldId={config.fieldId}
          value={config.formData[config.fieldId]}
          mainText={config.mainText}
          subText={config.subText}
          formData={config.formData}
          onChange={config.onChange}
          onAction={config.onAction}
          nextPageField={config.nextPageField}
          submitting={config.submitting}
        />
      );
    }
    case 'referralCheckboxes': {
      const referralCheckboxes = (
        <ReferralCheckboxes
          id={config.id}
          fieldId={config.fieldId}
          required={config.required}
          label={config.label}
          readonly={config.readonly}
          value={config.value || config.formData[config.fieldId]}
          onChange={config.onChange}
          formData={config.formData}
        />
      );
      if (config.readonly) {
        return referralCheckboxes;
      }
      return Utils.Component.wrap(config, referralCheckboxes);
    }
    default:
      break;
  }
  return null;
};

export default onGetComponent;
