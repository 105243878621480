const ROUTE_TITLES = Object.freeze({
  ROOT: 'pages.home.title',
  LOGIN: 'pages.login.title',
  ACCESSIBILITY: 'pages.accessibility.title',
  PRIVACY: 'pages.privacy.title',
  FORMS: 'pages.forms.list.title',
  REPORTS: 'pages.reports.list.title',
  TARGETS: 'pages.targets.title',
  TASKS: 'pages.tasks.groups.title',
  CASES: 'pages.cases.title',
  LOGOUT: 'pages.logout.title',
  EXISTING_ACCOUNT: 'pages.existing-account.title',
  CONFIRMATION: 'pages.confirmation-page.title',
  CONFIRMATION_COIR: 'pages.confirmation-page-coir.title',
  NEW_ACCOUNT: 'pages.new-account.title',
  ACCOUNT_UNDER_REVIEW: 'pages.pending-account.title',
  FORMS_ACCOUNT: 'pages.form.title',
  ACCOUNT_CONFIRMATION: 'pages.form.cop-confirmation-panel.title',
  SERVICE_NAME: 'header.service-name',
});

export default ROUTE_TITLES;
