// Global imports
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@ukhomeoffice/cop-react-components';

// Local imports
import { useAxios } from '../../utils/hooks';

const EpmsOfficerEmailsAutocomplete = ({
  id,
  fieldId,
  onChange,
  agency,
  item,
  value,
  ...attrs
}) => {
  const [emails, setEmails] = useState([]);
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchOfficers = async () => {
      try {
        const response = await axiosInstance.get('/camunda/data-service/officers');
        const options = response?.data
          .map((officer) => {
            return {
              value: officer.email,
              label: officer.email,
            };
          })
          .filter((p) => !!p);
        setEmails(options);
      } catch {
        setEmails([]);
      }
    };
    if (axiosInstance) {
      fetchOfficers();
    }
  }, [axiosInstance, agency]);

  return (
    <Autocomplete
      id={id}
      fieldId={fieldId}
      source={emails}
      showAllValues={false}
      displayMenu="inline"
      item={item}
      value={value}
      onChange={onChange}
      {...attrs}
    />
  );
};

EpmsOfficerEmailsAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  agency: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    format: PropTypes.string,
  }),
};

EpmsOfficerEmailsAutocomplete.defaultProps = {
  agency: null,
  value: null,
};

export default EpmsOfficerEmailsAutocomplete;
