/**
 * Function returns an error message if there is not exactly one driver,
 * or undefined if there is exactly one driver.
 * @param {string} fieldData - data related to the specific field being validated (not used in this function)
 * @param {object} validation - this parameter is not used in the function
 * @param {object} component - component definition
 * @param {object} data - form data for component containing people information
 * @returns {object|undefined} An error object if there is not exactly one driver, undefined otherwise
 */

/* eslint-disable no-unused-vars */
const mustHaveOneDriver = (fieldData, validation, component, data) => {
  const drivers =
    data.people?.filter((person) => person.travellingRole?.roletype === 'Driver') || [];
  const driverCount = drivers.length;

  let msg = '';
  if (driverCount === 0) {
    msg = 'You must add at least one driver';
  } else if (driverCount > 1) {
    msg = 'Only one person can be the driver - the person driving when you stopped them';
  }

  return msg
    ? {
        error: msg,
        id: component.id,
      }
    : undefined;
};

export default mustHaveOneDriver;
